import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFeatureStore = defineStore( 'feature', () => {
    // region State
    const features = ref<Record<string, string | boolean>>( {} );
    // endregion

    // region Actions
    const setFeatures = ( newFeatures: Record<string, string | boolean> ) => {
        features.value = newFeatures;
    };
    // endregion

    // region Getters
    const active = ( feature: string ) => !!( features.value[feature] ?? false );
    const value = ( feature: string ) => features.value[feature] ?? undefined;
    // endregion

    return {
        features,
        setFeatures,
        active,
        value,
    };
} );
