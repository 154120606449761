export function normalizeUrl( url: string | undefined ) {
    if ( !url ) {
        return '';
    }

    if ( !url.startsWith( 'http' ) ) {
        url = 'http://' + url;
    }

    return url.toLowerCase();
}

/**
 * Formats a URL for display
 *
 * @param url
 * @return string
 */
export function formatUrlForDisplay( url: string | URL | undefined | null ) {
    if ( !url ) {
        return '';
    }

    if ( !( url instanceof URL ) ) {
        try {
            url = new URL( url );
        } catch {
            // Malformed - not much we can do
            return url as string;
        }
    }

    return url.hostname;
}

export function formatUrlWithoutProtocol( url: string | URL | undefined | null ) {
    return url?.toString().replace( /(^\w+:|^)\/\//, '' );
}

/**
 * Guarantees an url relative to the app's domain
 *
 * @return URL
 * @param urlOrPath
 */
export function localUrl( urlOrPath: string ) {
    // SSR
    if ( typeof window === 'undefined' ) {
        return urlOrPath;
    }

    let path;

    if ( urlOrPath.startsWith( 'http' ) ) {
        path = new URL( urlOrPath ).pathname;
    } else {
        path = urlOrPath;
    }

    return new URL( path, window.location.origin ).href;
}

export function urlDescription( url: string ) {
    return {
        link: normalizeUrl( url ),
        text: formatUrlForDisplay( url ),
    };
}

export function pseudoUrl( url: string, label = '' ) {
    const description = urlDescription( url );
    const parts = description.text.split( '/' );
    const domain = parts[0];
    const segment = parts[1] || '';

    let pseudo = domain;

    if ( segment ) {
        pseudo += ` > ${segment}`;
    }

    if ( label ) {
        pseudo += ` > ${label}`;
    }

    return pseudo;
}

export function googleLink( name: string, country: string | null, city: string | null ) {
    let query = '"' + name + '"';
    if ( country ) {
        query += '+"' + country + '"';
    }
    if ( city ) {
        query += '+"' + city + '"';
    }
    return 'https://google.com/search?q=' + encodeURIComponent( query );
}

export function urlSafe( string: string ) {
    return string.replace( /\+/g, '-' ).replace( /\//g, '_' ).replace( /=/g, '' );
}
