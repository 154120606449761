export function csrfToken() {
    return globalThis.document.head
            .querySelector( 'meta[name="csrf-token"]' )
            ?.getAttribute( 'content' )
        ?? undefined;
}

export function cspNonce() {
    const meta = document.head.querySelector( 'meta[property="csrf-nonce"]' )
        ?? document.head.querySelector( 'meta[name="csrf-nonce"]' );

    if ( !meta || !( 'nonce' in meta ) || typeof meta.nonce !== 'string' ) {
        return undefined;
    }

    return meta.nonce;
}

export function fatalErrorHandler( error: Error ) {
    const style = globalThis.document.createElement( 'style' );
    style.nonce = cspNonce();

    // language=css
    style.innerText = `
        fatal-error {
            margin: 10vh auto 0;
            padding: 4rem;
            max-width: 40rem;
            font-family: 'Poppins', sans-serif;

            & h1 {
                font-weight: 600;
                margin-bottom: 0.5rem;
            }

            & p {
                margin: 0.5rem 0;
                line-height: 1.4;
            }

            & strong {
                font-weight: 500;
            }

            & pre {
                margin-top: 0.5rem;
                padding: 0.5rem;
                background: #eaeaf3;
                color: #3b3b51;
                border-radius: 5px;
                overflow-x: auto;
                box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
            }
        }
    `;

    globalThis.document.head.appendChild( style );
    globalThis.document.body.innerHTML = `
      <fatal-error>
        <h1>⚠️&nbsp;&nbsp;Critical Startup Error</h1>
        <p>
            Unfortunately, an error occurred while starting the application.<br>
            Please retry at a later time while we fix this issue.
        </p>
        <strong>Technical information:</strong>
        <pre><strong>${error}</strong>` +
        '\n' +
        `${error.stack?.split( '\n', 2 ).pop()}</pre>
      </fatal-error>
    `;

    throw error;
}
