import { context } from '@main/api';
import { METHOD_PUT } from '@main/api/plugin/types/api';
import type { ResizeOptions } from '@main/utilities/files';
import { resizeImage } from '@main/utilities/files';
import type { ResourcePayload } from '@main/utilities/types';

export type ApiMediaItem = {
    readonly uuid: string;
    readonly createdAt: string;
    readonly updatedAt: string;

    readonly complete: boolean;
    readonly encrypted: boolean;
    readonly url: string;

    mimeType: string;
    name: string;
    size: number;
    type: MediaItemType;
};

export type NewApiMediaItem = ResourcePayload<ApiMediaItem>;

export type MediaItemType =
    | 'certificateProof'
    | 'companyAvatar'
    | 'companyLogo'
    | 'projectImage'
    | 'rfxDocument'
    | 'userAvatar';

const { single, all, destroy, request, create } = context;

export async function fetchMediaItem( mediaItem: string ) {
    const { attributes } = await single<ApiMediaItem>(
        import.meta.route( 'api.v0.media.single', [mediaItem] ),
    );

    return attributes;
}

export async function fetchMediaItems() {
    const { data } = await all<ApiMediaItem>( import.meta.route( 'api.v0.media' ) );

    return data.map( ( { attributes } ) => attributes );
}

export async function deleteMediaItem( mediaItem: string ) {
    await destroy( import.meta.route( 'api.v0.media.delete', [mediaItem] ) );
}

export async function downloadMediaItem( mediaItem: string ) {
    const response = await request( 'GET', import.meta.route( 'api.v0.media.download', [mediaItem] ), {
        bypass: true,
    } );

    return response.blob();
}

export async function createMediaItem( file: File, type: MediaItemType, options?: ResizeOptions ) {
    // The image will be resized if a maximum size was given. Doing this on
    // the client side keeps load from our servers. Obviously, the size will
    // still be validated server-side!
    const blob = options ? await resizeImage( file, options ) : file;

    // Create the media item first
    const uuid = await create<ApiMediaItem>( import.meta.route( 'api.v0.media.create' ), {
        mimeType: blob.type,
        name: file.name,
        size: blob.size,
        type: type,
    } satisfies NewApiMediaItem );

    // If the server didn't send a valid Location header with a UUID in it,
    // something went wrong, but we can't do anything about this
    if ( !uuid ) {
        throw new Error( 'Unexpected response: Try again later' );
    }

    // Upload the actual image file
    await request( METHOD_PUT, import.meta.route( 'api.v0.media.upload', [uuid] ), {
        body: await blob.arrayBuffer(),
        headers: {
            Accept: 'application/json',
            'Content-Type': blob.type,
        },
    } );

    return uuid.toString();
}
