<script lang="ts" setup>
import { useHead } from '@unhead/vue';

useHead( {
    titleTemplate: ( title ) => ( title ? `${title} | Matchory` : 'Matchory: Supplier Discovery' ),
} );
</script>

<template>
    <v-app class="app-viewport">
        <router-view />
    </v-app>
</template>

<style lang="scss">
@use 'vuetify/src/styles/styles.sass';

html {
    // Fix always scrollbar shown.
    overflow-y: auto;
}
</style>
