import type { ContextRequestOptions } from '@main/api/plugin/types/api';
import {
    type ApiContextSummary,
    createAssistantConversation as CreateAssistantConversation,
    createAssistantSetupConversation as CreateAssistantSetupConversation,
    fetchAssistantConversation as FetchAssistantConversation,
    fetchAssistantConversationMessages as FetchAssistantConversationMessages,
    fetchAssistantConversations as FetchAssistantConversations,
    fetchAssistantSetupConversation as FetchAssistantSetupConversation,
    fetchEntryAssistantConversation as FetchEntryAssistantConversation,
    fetchEntryAssistantConversationMessages as FetchEntryAssistantConversationMessages,
    sendAssistantConversationCorkMessage as SendAssistantConversationCorkMessage,
    sendAssistantConversationMessage as SendAssistantConversationMessage,
    sendEntryAssistantConversationMessage as SendEntryAssistantConversationMessage,
} from '@main/api/resources/projects';
import { sleep } from '@main/utilities/misc';
import { patchList } from '@main/utilities/store';
import { acceptHMRUpdate, defineStore } from 'pinia';
import Vue, { ref } from 'vue';

export type ApiAssistantConversation = {
    readonly uuid: string;
    readonly pending: boolean;
    readonly setup: boolean;
    readonly createdAt: string;
    readonly updatedAt: string;
};

export type ApiAssistantConversationMessage = {
    readonly uuid: string;
    readonly createdAt: string;
    readonly content: string;
    readonly group: string;
    readonly role: 'assistant' | 'user';
    readonly suggestions: string[];
};

export type PendingMessage = Pick<
    ApiAssistantConversationMessage,
    'content' | 'suggestions' | 'uuid' | 'role' | 'group'
> & {
    failed: boolean;
    sent: boolean;
    status?: string;
};

const assistantTimeout = 20_000;

export const useAssistantsStore = defineStore( 'assistants', () => {
    // region State
    /**
     * All conversations per project UUID
     */
    const conversations = ref<Record<string, ApiAssistantConversation[]>>( {} );

    /**
     * Setup conversations per project UUID
     */
    const setupConversations = ref<Record<string, string>>( {} );

    /**
     * Entry conversations per entry UUID
     */
    const entryConversations = ref<Record<string, string>>( {} );

    /**
     * Conversation messages per conversation UUID
     */
    const conversationMessages = ref<Record<string, ApiAssistantConversationMessage[]>>( {} );

    /**
     * Summary of an assistant conversation, per conversation UUID
     */
    const conversationSummary = ref<Record<string, ApiContextSummary>>( {} );

    /**
     * Current pending messages, independent of the conversation
     */
    const pendingMessages = ref<PendingMessage[]>( [] );

    const assistantPending = ref( false );
    const abortController = ref<AbortController | undefined>( undefined );
    const pollingTimeout = ref<ReturnType<typeof setTimeout> | undefined>( undefined );
    const waitInterval = ref( 1000 );

    const assistantSlow = ref( false );
    const assistantSlowTimeout = ref<ReturnType<typeof setTimeout> | undefined>( undefined );

    // endregion

    // region Actions

    // region Conversations
    async function fetchOrCreateAssistantSetupConversation( projectUuid: string ) {
        let conversation: ApiAssistantConversation;

        try {
            conversation = await FetchAssistantSetupConversation( projectUuid );
        } catch {
            conversation = await CreateAssistantSetupConversation( projectUuid );
        }

        Vue.set( setupConversations.value, projectUuid, conversation.uuid );

        Vue.set(
            conversations.value,
            projectUuid,
            patchList( conversations.value[projectUuid], conversation, 'uuid' ),
        );
    }

    async function createAssistantConversation( projectUuid: string ) {
        const conversation = await CreateAssistantConversation( projectUuid );

        Vue.set(
            conversations.value,
            projectUuid,
            patchList( conversations.value[projectUuid], conversation, 'uuid' ),
        );

        return conversation;
    }

    async function fetchAssistantConversation(
        projectUuid: string,
        data: { conversationUuid: string; } | { entryUuid: string; },
    ) {
        const fetchConversation =
            'conversationUuid' in data
                ? FetchAssistantConversation.bind( null, projectUuid, data.conversationUuid )
                : FetchEntryAssistantConversation.bind( null, projectUuid, data.entryUuid );

        const conversation = await fetchConversation();

        Vue.set(
            conversations.value,
            projectUuid,
            patchList( conversations.value[projectUuid], conversation, 'uuid' ),
        );

        if ( 'entryUuid' in data ) {
            Vue.set( entryConversations.value, data.entryUuid, conversation.uuid );
        }

        return conversation;
    }

    async function fetchAssistantConversations( projectUuid: string ) {
        const apiConversations = await FetchAssistantConversations( projectUuid );

        Vue.set( conversations.value, projectUuid, [
            ...( conversations.value[projectUuid] ?? [] ),
            ...apiConversations,
        ] );
    }

    // endregion

    // region Conversation Messages
    async function fetchAssistantConversationMessages(
        projectUuid: string,
        data: { conversationUuid: string; } | { entryUuid: string; },
        options?: ContextRequestOptions,
    ) {
        const fetchMessages =
            'conversationUuid' in data
                ? FetchAssistantConversationMessages.bind( null, projectUuid, data.conversationUuid )
                : FetchEntryAssistantConversationMessages.bind( null, projectUuid, data.entryUuid );

        const {
            messages,
            summary, // TODO: we might not have a summary in entry conversation
            pending,
        } = await fetchMessages( options );

        // Reverse the messages so the latest one is at the bottom
        messages.reverse();

        const uuid =
            'conversationUuid' in data
                ? data.conversationUuid
                : entryConversations.value[data.entryUuid];

        if ( !conversationMessages.value[uuid] ) {
            Vue.set( conversationMessages.value, uuid, {} );
        }

        Vue.set( conversationMessages.value, uuid, messages );

        messages.forEach( ( message ) => removePendingMessage( message.group ) );

        if ( summary ) {
            Vue.set( conversationSummary.value, uuid, summary );
        }

        return { messages, pending, summary };
    }

    async function sendAssistantConversationMessage(
        projectUuid: string,
        data: { conversationUuid: string; } | { entryUuid: string; },
        content: string | string[],
    ) {
        const messages = ( Array.isArray( content ) ? content : [content] ).map( ( content ) => {
            return {
                content: content,
                failed: false,
                group: '',
                role: 'user' as const,
                sent: false,
                suggestions: [],
                uuid: '',
            };
        } );

        pendingMessages.value.push( ...messages );

        const sendMessage =
            'conversationUuid' in data
                ? SendAssistantConversationMessage.bind( null, projectUuid, data.conversationUuid )
                : SendEntryAssistantConversationMessage.bind( null, projectUuid, data.entryUuid );

        try {
            const group = await sendMessage( content );

            messages.forEach( ( message ) => {
                message.sent = true;
                message.group = group;
            } );
        } catch {
            messages.forEach( ( message ) => {
                message.failed = true;
            } );
        }

        if ( !assistantPending.value ) {
            await startPolling( projectUuid, data );
        }
    }

    async function sendAssistantConversationCorkMessage(
        projectUuid: string,
        conversationId: string,
    ) {
        try {
            await SendAssistantConversationCorkMessage( projectUuid, conversationId );
        } catch ( cause ) {
            throw new Error( 'Failed to cork conversation', { cause } );
        }

        if ( !assistantPending.value ) {
            let summary = undefined;
            let attempts = 0;

            while ( !summary && attempts < 30 ) {
                attempts++;

                await sleep( 1000 );

                ( { summary } = await FetchAssistantConversationMessages(
                    projectUuid,
                    conversationId,
                ) );
            }

            if ( !summary ) {
                throw new Error( 'Failed to fetch assistant conversation summary' );
            }

            Vue.set( conversationSummary.value, conversationId, summary );
        }
    }

    function removePendingMessage( group: string ) {
        pendingMessages.value = pendingMessages.value.filter( ( message ) => message.group !== group );
    }

    function resetPendingMessages() {
        pendingMessages.value.splice( 0 );
    }

    // endregion

    // region Polling
    async function startPolling(
        projectUuid: string,
        data: { conversationUuid: string; } | { entryUuid: string; },
        options?: ContextRequestOptions,
    ) {
        if ( abortController.value ) {
            abortController.value.abort();
        }

        abortController.value = new AbortController();
        const { signal } = abortController.value;

        try {
            const { pending } = await fetchAssistantConversationMessages( projectUuid, data, {
                ...options,
                signal,
            } );

            // Show typing indicator
            assistantPending.value = pending || pendingMessages.value.length > 0;
        } catch ( error ) {
            if ( !( error instanceof Error ) || signal.aborted ) {
                abortController.value = undefined;
                assistantPending.value = false;

                return;
            }
        }

        if ( assistantPending.value && !abortController.value.signal.aborted ) {
            // Schedule the next poll and return early. This keeps the polling loop
            // running until the server responds with a different status code.
            pollingTimeout.value = setTimeout( () => {
                void startPolling( projectUuid, data );
            }, waitInterval.value );

            setAssistantSlow();
        }

        if ( !assistantPending.value || abortController.value.signal.aborted ) {
            clearAssistantSlow();
        }
    }

    function stopPolling() {
        if ( typeof pollingTimeout.value !== 'undefined' ) {
            clearTimeout( pollingTimeout.value );
        }

        if ( abortController.value ) {
            abortController.value.abort( {
                reason: 'Polling stopped',
            } );
        }

        assistantPending.value = false;

        clearAssistantSlow();
    }

    function setAssistantSlow() {
        assistantSlowTimeout.value ??= setTimeout( () => {
            if ( assistantPending.value ) {
                assistantSlow.value = true;
            }
        }, assistantTimeout );
    }

    function clearAssistantSlow() {
        assistantSlow.value = false;

        if ( typeof assistantSlowTimeout.value !== 'undefined' ) {
            clearTimeout( assistantSlowTimeout.value );
            assistantSlowTimeout.value = undefined;
        }
    }

    // endregion

    // endregion

    // region Getters
    const getSetupAssistantConversation = ( projectUuid: string ) =>
        projectUuid in conversations.value
            ? conversations.value[projectUuid]?.find(
                  ( { uuid } ) => uuid === setupConversations.value[projectUuid],
              )
            : undefined;

    /**
     * Extract the step from the latest assistant message in the setup conversation. This
     * serves as a temporary solution until the assistant is overhauled.
     */
    const getSetupAssistantConversationStep = ( projectUuid: string ) => {
        const setupConversation = getSetupAssistantConversation( projectUuid );

        if ( !setupConversation ) {
            return undefined;
        }

        const latestAssistantMessage = conversationMessages.value[setupConversation.uuid]
            ?.slice()
            .reverse()
            .find( ( { role } ) => role === 'assistant' );

        return (
            latestAssistantMessage?.content
                // Extract step number and name until a colon or period is encountered.
                ?.match( /^Step (\d+\/\d+\s-\s[^.:]+)/ )
                ?.at( 1 )
        );
    };

    const getAssistantConversation = ( projectUuid: string, conversationId: string ) =>
        conversations.value[projectUuid]?.find( ( { uuid } ) => uuid === conversationId );

    const getProjectAssistantConversation = ( projectUuid: string ) => {
        return conversations.value[projectUuid]
            ?.filter(
                ( { setup, uuid } ) =>
                    !setup && !Object.values( entryConversations.value ).includes( uuid ),
            )
            .toSorted( ( a, b ) => ( a.createdAt > b.createdAt ? -1 : 1 ) )
            .at( 0 );
    };

    const getEntryAssistantConversation = ( projectUuid: string, entryUuid: string ) => {
        const conversationId = entryConversations.value[entryUuid];

        return getAssistantConversation( projectUuid, conversationId );
    };

    const getAssistantConversations = ( projectUuid: string ) =>
        conversations.value[projectUuid] || [];

    /**
     * TODO: overhaul getters simply returning state due to possible reactivity issues
     * @deprecated Use the store state directly instead
     */
    const getAssistantConversationMessages = ( conversationId: string ) =>
        conversationMessages.value[conversationId] || [];

    const getAssistantConversationSummary = ( conversationId: string ) =>
        conversationSummary.value[conversationId] || undefined;

    // endregion

    return {
        conversations,
        setupConversations,
        entryConversations,
        conversationMessages,
        conversationSummary,
        pendingMessages,
        assistantPending,
        abortController,
        pollingTimeout,
        waitInterval,
        assistantSlow,
        assistantSlowTimeout,

        fetchOrCreateAssistantSetupConversation,
        createAssistantConversation,
        fetchAssistantConversation,
        fetchAssistantConversations,
        fetchAssistantConversationMessages,
        sendAssistantConversationMessage,
        sendAssistantConversationCorkMessage,
        removePendingMessage,
        resetPendingMessages,
        startPolling,
        stopPolling,

        getSetupAssistantConversation,
        getSetupAssistantConversationStep,
        getProjectAssistantConversation,
        getAssistantConversation,
        getAssistantConversations,
        getAssistantConversationMessages,
        getAssistantConversationSummary,
        getEntryAssistantConversation,
    };
} );

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useAssistantsStore, import.meta.hot ) );
}
