import { context } from '@main/api';
import type {
    ContextRequestOptions,
    ListOptions,
    PaginationResult,
} from '@main/api/plugin/types/api';
import { METHOD_GET } from '@main/api/plugin/types/api';
import type {
    Attributes,
    DefaultMeta,
    Included,
    ResourceObject,
} from '@main/api/plugin/types/jsonApi';
import type { ApiCompanyProfile, ApiCompanySummary, ApiLink } from '@main/api/resources/companies';
import type { ApiRfxSubmission, RfxSupplierState } from '@main/api/resources/rfx';
import type { ApiQaLabel } from '@main/api/resources/search';
import type { ApiUser } from '@main/api/resources/users';
import type {
    ApiAssistantConversation,
    ApiAssistantConversationMessage,
} from '@main/store/stores/assistants';
import {
    extractLink,
    extractLinkedResourceId,
    extractPagination,
    forceInclude,
    hydrateRelationships,
    serializeOptions,
} from '@main/utilities/api';
import type { ResourcePayload } from '@main/utilities/types';

// region Project Resource

export type ApiProject = {
    readonly uuid: string;
    readonly owner: string;
    readonly imageUrl: string | null;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly permissions: ProjectPermissions;
    readonly aiEnabled: boolean;
    readonly containsSupplier?: boolean;

    // mutable
    name: string;
    description: string | null;
    tags: string[];
    keywords: string[];

    // includes
    readonly collaborators?: ApiCollaborator[];
    readonly phases?: ApiProjectPhase[];
    readonly entries?: ApiProjectEntry[];

    readonly setupAssistantConversation?: ApiAssistantConversation;

    // meta
    readonly wizardState: ProjectWizardState;
    readonly isQaProject: boolean;
};

export type ApiProjectWithPhases = {
    readonly phases: ApiProjectPhase[];
} & ApiProject;

export type NewApiProject = ResourcePayload<ApiProject> & {
    readonly imageUuid?: string;
    readonly contextSummary?: ApiContextSummary;
    readonly wizardState?: ProjectWizardState;
    readonly aiEnabled?: boolean;
};

export type UpdatedApiProject = ResourcePayload<ApiProject> & {
    readonly contextSummary?: ApiContextSummary;
    readonly imageUuid?: string;
    referenceUuids?: string[];
    readonly wizardState?: ProjectWizardState;
    readonly isQaProject?: boolean;
};

export type ApiContextSummary = {
    additionalInformation: string[];
    alternativeDesignations: string[];
    areaOfApplication: string[];
    location: string;
    manufacturingCapabilities: string;
    materials: string[];
    productsOrServices: string[];
    purpose: string;
    standardsOrCertificates: string[];
    supplierType: string[];
};

export type ProjectWizardState = 'keywords' | 'context' | 'references' | 'metadata' | 'finalized';

export type ProjectPermissions = {
    readonly addCollaborators: boolean;
    readonly read: boolean;
    readonly write: boolean;
};

export type ProjectPermissionsGrants = {
    readonly read: boolean;
    readonly write: boolean;
};

export type ApiProjectIncludes =
    | 'phases'
    | 'collaborators'
    | 'requirements'
    | 'requirementStatesCount'
    | 'defaultQuery'
    | 'setupAssistantConversation'
    | 'assistantConversations';

type ProjectMeta = DefaultMeta & {
    permissions: ProjectPermissions;
    containsSupplier: boolean;
    isQaProject: boolean;
    wizardState: ProjectWizardState;
    aggregations?: Record<AggregatableField, Record<string, number>>;
};

export type FetchApiProjectsReturn = {
    readonly projects: ApiProject[];
    readonly aggregations?: Record<AggregatableField, Record<string, number>>;
} & PaginationResult;

export type AggregatableField = 'tags';

// endregion

// region projects sort

export type LabeledSortOption = {
    label: string;
    field: ProjectsSortOption;
    descending: boolean;
};

export type ProjectsSortOption = 'createdAt' | 'name';

export const DEFAULT_DATE_SORT_OPTION: LabeledSortOption = {
    descending: true,
    field: 'createdAt',
    label: 'Newest first',
};

export const DEFAULT_NAME_SORT_OPTION: LabeledSortOption = {
    descending: false,
    field: 'name',
    label: 'A - Z',
};

// endregion

// region ProjectPhase Resource

export type ApiProjectPhase = {
    readonly uuid: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly count: number;
    readonly type: ProjectPhaseType;

    // mutable
    name: string;
    order: number;
};

export type ProjectPhaseType = 'active' | 'longlist' | 'shortlist' | 'deselected';

// endregion

// region Project Collaborator Resource

export type ApiCollaboratorMeta = {
    // Currently not delivered, in order to
    // avoid N+1 queries in the backend.
    // readonly grantedBy: string | null;

    readonly grantedAt: string;
    readonly permissions: ProjectPermissions;
};

export type ApiCollaborator = ApiUser & ApiCollaboratorMeta;

// endregion

// region Project Entry Types
export type ApiProjectEntry = {
    readonly uuid: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly companyUuid: string;

    readonly searchQuery: string;
    readonly keywords: string[] | null;

    readonly reference: boolean;

    // action states
    readonly discoveryState: DiscoveryState;
    readonly phaseUuid: string | null;
    readonly excluded: boolean;
    readonly reported: boolean;

    // action states from meta
    readonly visited: boolean;

    // includes
    readonly actions?: ApiProjectEntryAction[];
    readonly company: ApiCompanySummary;
    readonly rfxState?: RfxSupplierState | null;
    readonly links?: ApiLink[];

    // if the entry was created via a project-entry action,
    // then we map the returned action to this property
    readonly triggerAction?: ApiProjectEntryAction;
};

// Whenever a company is added to a phase for the first time,
// we can this a supplier discovery and record the state of
// the company at the point of discovery.
export type DiscoveryState =
    // This company is an active supplier of the user's company,
    // when it was discovered in Matchory.
    | 'active'

    // This company is a supplier that is known to the user,
    // when it was discovered.
    | 'known'

    // This company is a new supplier for the user that he didn't know before
    // he discovered it in Matchory.
    | 'new'

    // This state is assigned for project entries, that were added to a phase
    // before we introduced the discovery state.
    | 'unknown'

    // Discovery state is empty for entries that are not assigned to a phase.
    | '';

export type ApiProjectEntryMeta = DefaultMeta & {
    readonly visited: boolean;
    readonly rfxState: RfxSupplierState | null;
};

export type ApiProjectEntryIncludes = 'company' | 'requirementStates';

export type ApiProjectEntryFilters = 'phase' | 'excluded' | 'reference';

export type FetchApiProjectEntriesReturn = {
    readonly entries: ApiProjectEntry[];
} & PaginationResult;

// endregion

// region Project Entry Actions

export type NewApiProjectEntryCommentAction = {
    readonly comment: string;
    readonly searchQuery?: string;
    readonly keywords?: string[];
};

export type NewApiProjectEntrySelectAction = {
    readonly comment?: string;
    readonly searchQuery?: string;
    readonly keywords?: string[];
    readonly discoveryState?: DiscoveryState;
};

export type NewApiProjectEntryExcludeAction = {
    readonly excluded: boolean;
    readonly comment?: string;
    readonly reported?: boolean;
    readonly searchQuery?: string;
};

export type NewApiProjectEntryRateAction = {
    readonly rating: number;
    readonly comment?: string;
};

export type ApiProjectEntryActionType =
    | 'commented'
    | 'excluded'
    | 'included'
    | 'rated'
    | 'selected'
    | 'visited';

export type ApiProjectEntryAction = {
    uuid: string;
    action: ApiProjectEntryActionType;
    comment?: string;
    collaborator: ApiCollaborator;
    createdAt: string;
};

export type ApiProjectEntryCommentAction = {
    action: 'commented';
    comment: string;
} & ApiProjectEntryAction;

export type ApiProjectEntryExcludeAction = {
    action: 'excluded';
} & ApiProjectEntryAction;

export type ApiProjectEntryIncludeAction = {
    action: 'included';
} & ApiProjectEntryAction;

export type ApiProjectEntryRateAction = {
    action: 'rated';
    rating: number;
} & ApiProjectEntryAction;

export type ApiProjectEntrySelectAction = {
    action: 'selected';
    keywords: string[];
    phaseName: string;
    phaseUuid: string;
    searchQueryUuid: string | null;
} & ApiProjectEntryAction;

export type ApiProjectEntryVisitAction = {
    action: 'visited';
    comment: '';
} & ApiProjectEntryAction;

export type FetchApiProjectEntryActionsReturn = {
    readonly actions: ApiProjectEntryAction[];
} & PaginationResult;

// endregion

// region Project Requirement Resource

export type ProjectRequirementsType = 'checklist' | 'rfx';

export type ApiProjectRequirement<
    C extends ProjectRequirementConstraint = ProjectRequirementConstraint,
> = {
    readonly uuid: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly requirementStatesCount?: number;

    // mutable
    title: string;
    constraint: C;

    // includes
    readonly document?: ProjectRequirementDocument;
    readonly rfxStageUuid?: string;
};

export type NewApiProjectRequirement = ResourcePayload<ApiProjectRequirement> & {
    readonly documentUuid?: string;
};

// As opposed to creation, we do not allow to update the linked document.
export type UpdatedApiProjectRequirement = ResourcePayload<ApiProjectRequirement>;

export type ProjectRequirementDocument = {
    readonly size: number;
    readonly name: string;
    readonly url: string;
};

type BooleanProjectRequirementConstraint = 'accepted' | 'rejected';
type TextProjectRequirementConstraint = 'answered';
type DocumentProjectRequirementConstraint =
    | 'documentReceived'
    | 'documentAcknowledged'
    | 'documentProvided'; // | 'documentSigned' | 'certificateProvided';
export type ProjectRequirementConstraint =
    | BooleanProjectRequirementConstraint
    | TextProjectRequirementConstraint
    | DocumentProjectRequirementConstraint;
// | 'answered'
// | 'documentReceived'
// | 'documentAcknowledged'
// | 'documentProvided'
// | 'documentSigned'
// | 'choiceSelected'
// | 'numberInRange'
// | 'numberBelow'
// | 'numberAbove'
// | 'certificateProvided'

// endregion

// region Project Requirement State Resource

export type ApiProjectRequirementState = {
    readonly uuid: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly supplierUuid: string;
    readonly requirementUuid: string;

    // mutable
    checked: boolean | null;
    answer: string | null;

    // includes
    readonly document?: ProjectRequirementDocument;
};

export type NewApiProjectRequirementState = ResourcePayload<ApiProjectRequirementState> & {
    readonly documentUuid?: string;
};

export type UpdatedApiProjectRequirementState = ResourcePayload<ApiProjectRequirementState>;

export type FetchApiRequirementStatesReturn = PaginationResult & {
    readonly requirementStates: ApiProjectRequirementState[];
};

// endregion

// region Project Export Resource

export type ApiProjectExport = {
    readonly uuid: string;
    readonly ready: boolean;
    readonly format: string;
    // TODO-INDEX
    readonly fields: ( keyof ApiCompanyProfile )[];
};

export type NewApiProjectExport = {
    readonly format: string;
    // TODO-INDEX
    readonly fields: ( keyof ApiCompanyProfile )[];
};

// endregion

const { request, all, single, create, update, put, destroy } = context;

// region Projects

export async function createProject( project: NewApiProject ): Promise<ApiProject> {
    const { attributes, meta } = await create<ApiProject, Included, ProjectMeta>(
        import.meta.route( 'api.v0.projects.create' ),
        project,
        {
            returnResource: true,
            invalidates: [new RegExp( `/projects.*` )],
        },
    );

    return { ...attributes, ...meta };
}

export async function updateProject( project: string, data: UpdatedApiProject ): Promise<ApiProject> {
    const { attributes, meta } = await update<ApiProject, Attributes, ProjectMeta>(
        import.meta.route( 'api.v0.projects.update', [project] ),
        data,
        {
            invalidates: [new RegExp( `/projects.*` )],
        },
    );

    return {
        ...attributes,
        ...meta,
    };
}

export async function deleteProject( project: string ) {
    await destroy( import.meta.route( 'api.v0.projects.delete', [project] ), {
        invalidates: [new RegExp( `/projects.*` )],
    } );
}

export async function fetchProjects(
    options?: ListOptions<ApiProject, ApiProjectIncludes> & { aggregations?: AggregatableField[]; },
) {
    if ( options?.aggregations ) {
        if ( !options.searchParams ) {
            options.searchParams = new URLSearchParams();
        }

        options.searchParams.set( 'aggregations', options.aggregations.join( ',' ) );

        // Remove aggregations from options since it is
        // incorporated in searchParams by now; and it
        // should not be appended again in serialization.
        delete options.aggregations;
    }

    const {
        data,
        meta: collectionMeta,
        included,
    } = await all<ApiProject, ApiCollaborator | ApiProjectPhase | ApiProjectEntry, ProjectMeta>(
        import.meta.route( 'api.v0.projects' ),
        {
            searchParams: options ? serializeOptions( options ) : undefined,
        },
    );

    const projects = data.map(
        ( { attributes, links, relationships, meta } ): ApiProject => ( {
            ...attributes,
            ...hydrateRelationships( relationships, included ),
            imageUrl: extractLink( { links }, 'image' ),
            ...meta,
        } ),
    );

    return {
        projects,
        ...extractPagination( collectionMeta ),
        aggregations: collectionMeta!.aggregations!,
    };
}

export async function fetchProject(
    project: string,
    includes?: ApiProjectIncludes[],
    suppressErrorHandling = false,
): Promise<ApiProject> {
    const response = await single<
        ApiProject,
        ApiCollaborator | ApiProjectPhase | ApiProjectEntry | ApiProjectRequirement,
        {
            permissions: ProjectPermissions;
            wizardState: ProjectWizardState;
        }
    >( import.meta.route( 'api.v0.projects.single', [project] ), {
        searchParams: {
            include: includes ?? [],
            suppressErrorHandling,
        },
    } );

    return {
        ...response.attributes,
        ...hydrateRelationships( response.relationships, response.included ),
        imageUrl: extractLink( response, 'image' ),
        ...response.meta,
    };
}

export async function fetchProjectsContainingSupplier(
    supplier: string,
    options?: ListOptions<ApiProject, ApiProjectIncludes>,
) {
    const {
        data,
        meta: collectionMeta,
        included,
    } = await all<ApiProject, ApiCollaborator | ApiProjectPhase | ApiProjectEntry, ProjectMeta>(
        import.meta.route( 'api.v0.companies.projects', [supplier] ),
        { searchParams: options ? serializeOptions( options ) : undefined },
    );

    const projects = data.map(
        ( { attributes, links, relationships, meta } ): ApiProject => ( {
            ...attributes,
            ...hydrateRelationships( relationships, included ),
            imageUrl: extractLink( { links }, 'image' ),
            ...meta,
        } ),
    );

    return { projects, ...extractPagination( collectionMeta ) };
}

// endregion

// region Project Assistant

export async function createAssistantSetupConversation( project: string ) {
    const { attributes } = await put<ApiAssistantConversation>(
        import.meta.route( 'api.v0.projects.assistant.conversations.setup.create', [project] ),
        {},
    );

    return attributes;
}

export async function fetchAssistantSetupConversation( project: string ) {
    const { attributes } = await single<ApiAssistantConversation>(
        import.meta.route( 'api.v0.projects.assistant.conversations.setup', [project] ),
        { suppressErrorHandling: true },
    );

    return attributes;
}

export async function createAssistantConversation( project: string ) {
    const { attributes } = await create<ApiAssistantConversation>(
        import.meta.route( 'api.v0.projects.assistant.conversations.create', [project] ),
        {},
        { returnResource: true },
    );

    return attributes;
}

export async function fetchAssistantConversation( project: string, conversation: string ) {
    const { attributes } = await single<ApiAssistantConversation>(
        import.meta.route( 'api.v0.projects.assistant.conversations.single', [
            project,
            conversation,
        ] ),
        {
            suppressErrorHandling: true,
        },
    );

    return attributes;
}

export async function fetchLatestAssistantConversation( project: string ) {
    const { attributes } = await single<ApiAssistantConversation>(
        import.meta.route( 'api.v0.projects.assistant.conversations.latest', [project] ),
    );

    return attributes;
}

export async function fetchAssistantConversations( project: string ) {
    const { data } = await all<ApiAssistantConversation>(
        import.meta.route( 'api.v0.projects.assistant.conversations', [project] ),
    );

    return data.map( ( { attributes } ) => attributes );
}

export async function fetchAssistantConversationMessages(
    project: string,
    conversation: string,
    options?: ContextRequestOptions,
) {
    const { data, meta } = await all<
        ApiAssistantConversationMessage,
        Attributes,
        {
            summary: ApiContextSummary;
            pending: boolean;
        }
    >(
        import.meta.route( 'api.v0.projects.assistant.conversations.messages', [
            project,
            conversation,
        ] ),
        {
            ...options,

            // Do not cache to always get latest messages
            bypass: true,
        },
    );

    const messages = data.map( ( { attributes } ) => attributes );
    const { summary, pending } = meta ?? {};

    return { messages, pending, summary };
}

export async function sendAssistantConversationMessage(
    project: string,
    conversation: string,
    content: string | string[],
) {
    const group = await create<ApiAssistantConversationMessage>(
        import.meta.route( 'api.v0.projects.assistant.conversations.messages.create', [
            project,
            conversation,
        ] ),
        { content },
    );

    if ( !group ) {
        throw new Error( 'Expected group in response.' );
    }

    return group;
}

export async function sendAssistantConversationCorkMessage( project: string, conversation: string ) {
    await create<ApiAssistantConversationMessage>(
        import.meta.route( 'api.v0.projects.assistant.conversations.cork', [project, conversation] ),
        { suppressErrorHandling: true },
    );
}

// endregion

// region Entry Assistant
export async function fetchEntryAssistantConversation( project: string, entry: string ) {
    const { attributes } = await single<ApiAssistantConversation>(
        import.meta.route( 'api.v0.projects.entries.conversation', [project, entry] ),
    );

    return attributes;
}

export async function fetchEntryAssistantConversationMessages(
    project: string,
    entry: string,
    options?: ContextRequestOptions,
) {
    const { data, meta } = await all<
        ApiAssistantConversationMessage,
        Attributes,
        {
            pending: boolean;
            summary: ApiContextSummary;
        }
    >( import.meta.route( 'api.v0.projects.entries.conversation.messages', [project, entry] ), {
        ...options,
        // Do not cache to always get latest messages
        bypass: true,
    } );

    const messages = data.map( ( { attributes } ) => attributes );
    const { summary, pending } = meta ?? {};

    return { messages, pending, summary };
}

export async function sendEntryAssistantConversationMessage(
    project: string,
    entry: string,
    content: string | string[],
) {
    const group = await create<ApiAssistantConversationMessage>(
        import.meta.route( 'api.v0.projects.entries.conversation.messages.create', [project, entry] ),
        { content },
    );

    if ( !group ) {
        throw new Error( 'Expected group in response.' );
    }

    return group;
}

// endregion

// region Phases

export async function fetchProjectPhases( project: string ) {
    const { data } = await all<ApiProjectPhase>(
        import.meta.route( 'api.v0.projects.phases', [project] ),
    );

    return data.map( ( { attributes } ) => attributes );
}

// endregion

// region Project Collaborators

export async function addProjectCollaborator(
    project: string,
    user: string,
    permissions: ProjectPermissionsGrants,
) {
    await put(
        import.meta.route( 'api.v0.projects.collaborators.add', [project, user] ),
        { permissions },
        {
            invalidates: ['projects', `projects/${project}`, `projects/${project}/collaborators?`],
        },
    );
}

export async function updateProjectCollaborator(
    project: string,
    user: string,
    permissions: ProjectPermissionsGrants,
) {
    await update(
        import.meta.route( 'api.v0.projects.collaborators.update', [project, user] ),
        { permissions },
        {
            invalidates: ['projects', `projects/${project}`, `projects/${project}/collaborators?`],
        },
    );
}

export async function removeProjectCollaborator( project: string, user: string ) {
    await destroy( import.meta.route( 'api.v0.projects.collaborators.remove', [project, user] ), {
        invalidates: ['projects', `projects/${project}`, `projects/${project}/collaborators?`],
    } );
}

export async function fetchProjectCollaborators( project: string ) {
    const { data } = await all<ApiUser, Included, ApiCollaboratorMeta>(
        import.meta.route( 'api.v0.projects.collaborators', [project] ),
    );

    return data.map(
        ( { attributes, meta } ): ApiCollaborator => ( {
            ...attributes,
            grantedAt: meta!.grantedAt,
            permissions: meta!.permissions,

            // Currently not delivered, in order to avoid N+1 queries in the backend.
            // grantedBy: meta!.grantedBy,
        } ),
    );
}

export async function fetchProjectCollaborator(
    project: string,
    user: string,
): Promise<ApiCollaborator> {
    const { attributes, meta } = await single<ApiCollaborator, Included, ApiCollaboratorMeta>(
        import.meta.route( 'api.v0.projects.collaborators.single', [project, user] ),
    );

    return { ...attributes, ...meta };
}

// endregion

// region Project Phases & Entries

export async function fetchProjectEntry( project: string, entry: string ) {
    const response = await single<ApiProjectEntry, ApiCompanySummary, ApiProjectEntryMeta>(
        import.meta.route( 'api.v0.projects.entries.single', [project, entry] ),

        // All entries with included profiles
        { searchParams: { include: ['company'] } },
    );

    return projectEntryFromResponse( response );
}

export async function fetchProjectEntryRequirementStates(
    project: string,
    entry: string,
    options?: ListOptions<ApiProjectRequirementState>,
) {
    const { data, included, meta } = await all<
        ApiProjectRequirementState,
        ProjectRequirementDocument
    >( import.meta.route( 'api.v0.projects.entries.requirementStates', [project, entry] ), {
        searchParams: serializeOptions( {
            ...options,
            include: forceInclude( ['documents'], options ),
        } ),
    } );

    const requirementStates = data.map( ( response ) => ( {
        ...response.attributes,
        requirementUuid: extractLinkedResourceId( response, 'requirement' ),
        ...hydrateRelationships( response.relationships, included ),
    } ) );

    return { requirementStates, ...extractPagination( meta ) };
}

export async function fetchProjectEntryRfxSubmission( project: string, entry: string ) {
    const { attributes } = await single<ApiRfxSubmission>(
        import.meta.route( 'api.v0.projects.entries.submission', [project, entry] ),

        // Make sure 404 does not get propagated
        { suppressErrorHandling: true },
    );

    return attributes;
}

export async function fetchProjectPhaseEntries(
    project: string,
    phase: string,
    options?: ListOptions<ApiProjectEntry, ApiProjectEntryIncludes>,
) {
    return fetchEntries( project, {
        ...options,

        // All entries with given phase that are not excluded
        filter: {
            phase: phase,
            excluded: 'false',
        },
        include: forceInclude( ['company'], options ),
    } );
}

export async function fetchProjectReferenceEntries(
    project: string,
    options?: ListOptions<ApiProjectEntry, ApiProjectEntryIncludes>,
) {
    return fetchEntries( project, {
        ...options,

        // All entries that are references
        filter: { reference: 'true' },
        include: forceInclude( ['company'] as ApiProjectEntryIncludes[], options ),
    } );
}

export async function fetchExcludedProjectEntries(
    project: string,
    options?: ListOptions<ApiProjectEntry, ApiProjectEntryIncludes, ApiProjectEntryFilters>,
) {
    return fetchEntries( project, {
        ...options,

        // All entries that were excluded by the user.
        filter: { excluded: 'true' },
        include: forceInclude( ['company'] as ApiProjectEntryIncludes[], options ),
    } );
}

// endregion

// region Project Entry Actions

export async function addProjectEntrySelectAction(
    project: string,
    phase: string,
    company: string,
    action: NewApiProjectEntrySelectAction,
) {
    const response = await put<ApiProjectEntry, ApiProjectEntryAction, ApiProjectEntryMeta>(
        import.meta.route( 'api.v0.projects.phases.entries.add', [project, phase, company] ),
        {
            ...( action.keywords && { keywords: action.keywords } ),
            ...( action.searchQuery && { searchQuery: action.searchQuery } ),
            ...( action.discoveryState && { discoveryState: action.discoveryState } ),
            comment: action.comment,
        },
        {
            invalidates: [
                // The selection actions changes the phase.
                // We need to invalidate /projects root
                // as fetching all projects may include the phases.
                new RegExp( `/projects.*` ),
                `companies/${company}/projects`,
            ],
        },
    );

    if ( !response ) {
        throw new Error( 'Expected entry in response.' );
    }

    return projectEntryFromResponse( response );
}

export async function addProjectEntryVisitAction( project: string, company: string ) {
    const response = await create<ApiProjectEntry, ApiProjectEntryAction, ApiProjectEntryMeta>(
        import.meta.route( 'api.v0.projects.entries.actions.visits.add', [project, company] ),
        {},
        {
            invalidates: [new RegExp( `/projects.*` ), `companies/${company}/projects`],
            returnResource: true,
        },
    );

    if ( !response ) {
        throw new Error( 'Expected entry in response.' );
    }

    return projectEntryFromResponse( response );
}

export async function addProjectEntryExcludeAction(
    project: string,
    company: string,
    action: NewApiProjectEntryExcludeAction,
) {
    const response = await create<ApiProjectEntry, ApiProjectEntryAction, ApiProjectEntryMeta>(
        import.meta.route( 'api.v0.projects.entries.actions.exclusions.add', [project, company] ),
        action,
        {
            returnResource: true,
            invalidates: [
                // The exclusion actions changes the phase.
                // We need to invalidate /projects root
                // as fetching all projects my include the phases.
                new RegExp( `/projects.*` ),
                `companies/${company}/projects`,
            ],
        },
    );

    if ( !response ) {
        throw new Error( 'Expected entry in response.' );
    }

    return projectEntryFromResponse( response );
}

export async function addProjectEntryCommentAction(
    project: string,
    company: string,
    action: NewApiProjectEntryCommentAction,
) {
    const response = await create<ApiProjectEntry, ApiProjectEntryAction, ApiProjectEntryMeta>(
        import.meta.route( 'api.v0.projects.entries.actions.comments.add', [project, company] ),
        action,
        {
            returnResource: true,
            invalidates: [new RegExp( `/projects/${project}.*` )],
        },
    );

    if ( !response ) {
        throw new Error( 'Expected entry in response.' );
    }

    return projectEntryFromResponse( response );
}

export async function addProjectEntryRateAction(
    project: string,
    company: string,
    action: NewApiProjectEntryRateAction,
) {
    const response = await create<ApiProjectEntry, ApiProjectEntryAction, ApiProjectEntryMeta>(
        import.meta.route( 'api.v0.projects.entries.actions.ratings.add', [project, company] ),
        action as Partial<ApiProjectEntry>,
        {
            returnResource: true,
            invalidates: [new RegExp( `/projects/${project}.*` )],
        },
    );

    if ( !response ) {
        throw new Error( 'Expected entry in response.' );
    }

    return projectEntryFromResponse( response );
}

export async function fetchProjectEntryActions(
    project: string,
    entry: string,
    options?: ListOptions<ApiProjectEntryAction>,
): Promise<FetchApiProjectEntryActionsReturn> {
    // For the time being, we are interested in all actions but visits.
    const filteredActions: ApiProjectEntryActionType[] = [
        'selected',
        'excluded',
        'included',
        'commented',
        'rated',
    ];

    const {
        data,
        meta: collectionMeta,
        included,
    } = await all<ApiProjectEntryAction, ApiCollaborator>(
        import.meta.route( 'api.v0.projects.entries.actions', [project, entry] ),
        {
            searchParams: serializeOptions<ListOptions<ApiProjectEntryAction>>( {
                ...options,
                filter: {
                    action: filteredActions.join( ',' ),
                },
            } ),
        },
    );

    const actions = data.map( ( { id: uuid, attributes, relationships } ): ApiProjectEntryAction => {
        return {
            ...attributes,
            ...hydrateRelationships( relationships, included ),
            uuid,
        };
    } );

    return { actions, ...extractPagination( collectionMeta ) };
}

// endregion

// region Project Requirements

export async function createChecklistRequirement( project: string, data: NewApiProjectRequirement ) {
    const { attributes } = await create<ApiProjectRequirement>(
        import.meta.route( 'api.v0.projects.checklist.requirements.create', [project] ),
        data,
        {
            returnResource: true,
            invalidates: [
                `projects/${project}/checklist/requirements`,
                new RegExp( `projects/${project}/checklist/requirements.*` ),
            ],
        },
    );

    return attributes;
}

export async function createRfxRequirement(
    project: string,
    stage: string,
    data: NewApiProjectRequirement,
) {
    const { attributes } = await create<ApiProjectRequirement>(
        import.meta.route( 'api.v0.projects.rfx.stages.requirements.create', [project, stage] ),
        data,
        {
            returnResource: true,
            invalidates: [
                `projects/${project}/rfx/stages/${stage}/requirements`,
                new RegExp( `projects/${project}/rfx/stages/${stage}/requirements.*` ),
            ],
        },
    );

    return attributes;
}

export async function updateChecklistRequirement(
    project: string,
    requirement: string,
    data: UpdatedApiProjectRequirement,
) {
    const { attributes } = await update<ApiProjectRequirement>(
        import.meta.route( 'api.v0.projects.checklist.requirements.update', [project, requirement] ),
        data,
        {
            // Make sure 409 does not get propagated as it is an
            // expected error. Parts of the requirement such as the
            // constraint cannot be changed, because there are already
            // states available.
            suppressErrorHandling: true,
            invalidates: [
                new RegExp( `projects/${project}/checklist/requirements.*` ),
                new RegExp( `projects/${project}/checklist/requirements/${requirement}.*` ),

                new RegExp( `projects/${project}/requirements/${requirement}/states.*` ),
                new RegExp( `projects/${project}/entries/.*/requirement-states.*` ),
            ],
        },
    );

    return attributes;
}

export async function updateRfxRequirement(
    project: string,
    stage: string,
    requirement: string,
    data: UpdatedApiProjectRequirement,
) {
    const { attributes } = await update<ApiProjectRequirement>(
        import.meta.route( 'api.v0.projects.rfx.stages.requirements.update', [
            project,
            stage,
            requirement,
        ] ),
        data,
        {
            // Make sure 409 does not get propagated as it is an
            // expected error. Parts of the requirement such as the
            // constraint cannot be changed, because there are already
            // states available.
            suppressErrorHandling: true,
            invalidates: [
                new RegExp( `/projects/${project}/rfx/stages/${stage}/requirements.*` ),
                new RegExp(
                    `/projects/${project}/rfx/stages/${stage}/requirements/${requirement}.*`,
                ),

                new RegExp( `projects/${project}/requirements/${requirement}/states.*` ),
                new RegExp( `projects/${project}/entries/.*/requirement-states.*` ),
            ],
        },
    );

    return attributes;
}

export async function deleteChecklistRequirement( project: string, requirement: string ) {
    await destroy(
        import.meta.route( 'api.v0.projects.checklist.requirements.remove', [project, requirement] ),
        {
            // Make sure 409 does not get propagated as it is an expected error.
            // The requirement cannot be removed, because there are already states available.
            suppressErrorHandling: true,
            invalidates: [
                new RegExp( `/projects/${project}/checklist/requirements.*` ),
                new RegExp( `/projects/${project}/checklist/requirements/${requirement}.*` ),

                // All states get deleted as well.
                new RegExp( `projects/${project}/requirements/${requirement}/states.*` ),
                new RegExp( `projects/${project}/entries/.*/requirement-states.*` ),
            ],
        },
    );
}

export async function deleteRfxRequirement( project: string, stage: string, requirement: string ) {
    await destroy(
        import.meta.route( 'api.v0.projects.rfx.stages.requirements.remove', [
            project,
            stage,
            requirement,
        ] ),
        {
            // Make sure 409 does not get propagated as it is an expected
            // error. The requirement cannot be removed, because there are
            // already states available.
            suppressErrorHandling: true,
            invalidates: [
                new RegExp( `/projects/${project}/rfx/stages/${stage}/requirements.*` ),
                new RegExp(
                    `/projects/${project}/rfx/stages/${stage}/requirements/${requirement}.*`,
                ),

                // All states get deleted as well.
                new RegExp( `projects/${project}/requirements/${requirement}/states.*` ),
                new RegExp( `projects/${project}/entries/.*/requirement-states.*` ),
            ],
        },
    );
}

export async function fetchChecklistRequirements( project: string ) {
    const { data, included } = await all<ApiProjectRequirement, ProjectRequirementDocument>(
        import.meta.route( 'api.v0.projects.checklist.requirements', [project] ),
        { searchParams: { include: ['documents'] } },
    );

    return data.map(
        ( { attributes, relationships } ): ApiProjectRequirement => ( {
            ...attributes,
            ...hydrateRelationships( relationships, included ),
        } ),
    );
}

export async function fetchRfxRequirements( project: string, stage: string ) {
    const { data, included } = await all<ApiProjectRequirement, ProjectRequirementDocument>(
        import.meta.route( 'api.v0.projects.rfx.stages.requirements', [project, stage] ),
        { searchParams: { include: ['documents'] } },
    );

    return data.map(
        ( data ): ApiProjectRequirement => ( {
            ...data.attributes,
            ...hydrateRelationships( data.relationships, included ),
            rfxStageUuid: extractLinkedResourceId( data, 'rfxStage' ),
        } ),
    );
}

export async function fetchChecklistRequirement(
    project: string,
    requirement: string,
): Promise<ApiProjectRequirement> {
    const response = await single<ApiProjectRequirement, ProjectRequirementDocument>(
        import.meta.route( 'api.v0.projects.checklist.requirements.single', [project, requirement] ),
        { searchParams: { include: ['document'] } },
    );

    return {
        ...response.attributes,
        ...hydrateRelationships<ProjectRequirementDocument>(
            response.relationships,
            response.included,
        ),
        rfxStageUuid: extractLinkedResourceId( response, 'rfxStage' ),
    };
}

export async function fetchRfxRequirement(
    project: string,
    stage: string,
    requirement: string,
): Promise<ApiProjectRequirement> {
    const { attributes, relationships, included } = await single<
        ApiProjectRequirement,
        ProjectRequirementDocument
    >(
        import.meta.route( 'api.v0.projects.rfx.stages.requirements.single', [
            project,
            stage,
            requirement,
        ] ),
        { searchParams: { include: ['document'] } },
    );

    return {
        ...attributes,
        ...hydrateRelationships<ProjectRequirementDocument>( relationships, included ),
    };
}

// endregion

// region Project Requirement States

export async function createRequirementState(
    project: string,
    requirement: string,
    data: NewApiProjectRequirementState,
    supplierUuid?: string,
): Promise<ApiProjectRequirementState> {
    // If no supplier UUID is given, then the current user is expected
    // to be an anonymous RFX supplier and the field is automatically
    // set on the backend. In that case, the
    // `projects/${ project }/entries` route is never used.

    const payload: NewApiProjectRequirementState & {
        supplierUuid?: string;
    } = {
        ...data,
        ...( supplierUuid && { supplierUuid } ),
    };

    const response = await create<ApiProjectRequirementState>(
        import.meta.route( 'api.v0.projects.requirements.states.create', [project, requirement] ),
        payload,
        {
            returnResource: true,
            invalidates: [
                new RegExp( `projects/${project}/requirements/${requirement}/states.*` ),
                new RegExp( `projects/${project}/entries/${supplierUuid}/requirement-states.*` ),
            ],
        },
    );

    return {
        ...response.attributes,
        requirementUuid: extractLinkedResourceId( response, 'requirement' ),
    };
}

export async function updateRequirementState(
    project: string,
    requirement: string,
    state: string,
    data: NewApiProjectRequirementState,
    supplierUuid?: string,
): Promise<ApiProjectRequirementState> {
    // If no supplier UUID is given, then the current
    // user is expected to be an anonymous RFX supplier
    // and the field is automatically set on the backend.
    // In that case, the `projects/${ project }/entries/
    // route is never used.

    const payload: NewApiProjectRequirementState & {
        supplierUuid?: string;
    } = {
        ...data,
        ...( supplierUuid && { supplierUuid } ),
    };

    const response = await update<ApiProjectRequirementState>(
        import.meta.route( 'api.v0.projects.requirements.states.update', [
            project,
            requirement,
            state,
        ] ),
        payload,
        {
            invalidates: [
                new RegExp( `projects/${project}/requirements/${requirement}/states.*` ),
                new RegExp( `projects/${project}/entries/${supplierUuid}/requirement-states.*` ),
            ],
        },
    );

    return {
        ...response.attributes,
        requirementUuid: extractLinkedResourceId( response, 'requirement' ),
    };
}

export async function deleteRequirementState( project: string, requirement: string, state: string ) {
    await destroy(
        import.meta.route( 'api.v0.projects.requirements.states.remove', [
            project,
            requirement,
            state,
        ] ),
        {
            invalidates: [
                new RegExp( `projects/${project}/requirements/${requirement}/states.*` ),
                new RegExp( `projects/${project}/entries/.*/requirement-states.*` ),
            ],
        },
    );
}

export async function deleteRequirementStates( project: string, requirement: string ) {
    await destroy(
        import.meta.route( 'api.v0.projects.requirements.states.purge', [project, requirement] ),
        {
            invalidates: [
                new RegExp( `projects/${project}/requirements/${requirement}/states.*` ),
                new RegExp( `projects/${project}/entries/.*/requirement-states.*` ),
            ],
        },
    );
}

export async function fetchRequirementStates(
    project: string,
    requirement: string,
    options?: ListOptions<ApiProjectRequirementState, string, 'latest'>,
): Promise<FetchApiRequirementStatesReturn> {
    const { data, included, meta } = await all<
        ApiProjectRequirementState,
        ProjectRequirementDocument
    >( import.meta.route( 'api.v0.projects.requirements.states', [project, requirement] ), {
        searchParams: options ? serializeOptions( options ) : undefined,
    } );

    const requirementStates = data.map(
        ( response ): ApiProjectRequirementState => ( {
            ...response.attributes,
            requirementUuid: extractLinkedResourceId( response, 'requirement' ),
            ...hydrateRelationships( response.relationships, included ),
        } ),
    );

    return { requirementStates, ...extractPagination( meta ) };
}

export async function fetchRequirementState(
    project: string,
    requirement: string,
    state: string,
): Promise<ApiProjectRequirementState> {
    const response = await single<ApiProjectRequirementState, ProjectRequirementDocument>(
        import.meta.route( 'api.v0.projects.requirements.states.single', [
            project,
            requirement,
            state,
        ] ),
        { searchParams: { include: ['document'] } },
    );

    return {
        ...response.attributes,
        requirementUuid: extractLinkedResourceId( response, 'requirement' ),
        ...hydrateRelationships<ProjectRequirementDocument>(
            response.relationships,
            response.included,
        ),
    };
}

// endregion

// region Project Exports

export function createProjectExport(
    project: string,
    fields: ( keyof ApiCompanyProfile )[],
    format: string,
) {
    return create<NewApiProjectExport>(
        import.meta.route( 'api.v0.projects.exports.export', [project] ),
        { format, fields },
    );
}

export async function fetchProjectExport( project: string, export_: string ) {
    const { attributes } = await single<ApiProjectExport>(
        import.meta.route( 'api.v0.projects.exports.single', [project, export_] ),
        { suppressErrorHandling: true, bypass: true },
    );

    return attributes;
}

export async function downloadProjectExport( project: string, export_: string ) {
    const response = await request(
        METHOD_GET,
        import.meta.route( 'api.v0.projects.exports.download', [project, export_] ),
        { headers: { Accept: '*/*' } },
    );

    return response.blob();
}

// endregion

// region Helper

const fetchEntries = async function (
    uuid: string,
    options: ListOptions<ApiProjectEntry, ApiProjectEntryIncludes, ApiProjectEntryFilters>,
) {
    const {
        data,
        meta: collectionMeta,
        included,
    } = await all<ApiProjectEntry, ApiCompanySummary, ApiProjectEntryMeta>(
        import.meta.route( 'api.v0.projects.entries', [uuid] ),
        { searchParams: serializeOptions( options ) },
    );

    const entries = data.map( ( { id, attributes, meta } ): ApiProjectEntry => {
        const company = included?.find(
            ( company ) => company?.attributes.uuid === attributes.companyUuid,
        );

        if ( !company ) {
            throw new Error(
                'Included company expected. The current index might not contain this company anymore.',
            );
        }

        return {
            ...attributes,
            company: {
                ...company.attributes,
                logoUrl: extractLink( company, 'logo' ),
                profileUrl: extractLink( company, 'profile' ),
                websitePreviewUrl: extractLink( company, 'websitePreview' ),
            },
            rfxState: meta!.rfxState,
            uuid: id,
            visited: meta!.visited,
        };
    } );

    return { entries, ...extractPagination( collectionMeta ) };
};

// region QA

export async function updateQaProject( projectUuid: string, declareQaProject: boolean ) {
    return declareQaProject ? createQaProject( projectUuid ) : removeQaProject( projectUuid );
}

async function createQaProject( projectUuid: string ) {
    return await create(
        import.meta.route( 'api.v0.projects.qa.projects.create', [projectUuid] ),
        {
            // no properties to be sent to create a QA project
        },
        {
            invalidates: [
                new RegExp( `projects` ),
                new RegExp( `projects/${projectUuid}.` ),
                new RegExp( `projects/${projectUuid}/.*` ),
            ],
            returnResource: false,
        },
    );
}

async function removeQaProject( projectUuid: string ) {
    return await destroy( import.meta.route( 'api.v0.projects.qa.projects.delete', [projectUuid] ), {
        invalidates: [
            new RegExp( `projects` ),
            new RegExp( `projects/${projectUuid}.*` ),
            new RegExp( `projects/${projectUuid}/.*` ),
        ],
    } );
}

export async function labelResult( projectUuid: string, companyUuid: string, { value }: ApiQaLabel ) {
    const { attributes } = await create<ApiQaLabel>(
        import.meta.route( 'api.v0.projects.qa.labels.create', [projectUuid] ),
        { companyUuid, value },
        {
            invalidates: [
                // .* without / to invalidate all routes including those with query parameters
                new RegExp( `projects/${projectUuid}.*` ),
            ],
            returnResource: true,
        },
    );

    return attributes;
}

// endregion

// endregion

const projectEntryFromResponse = function (
    response: ResourceObject<
        ApiProjectEntry,
        ApiProjectEntryMeta,
        Included<ApiCompanySummary | ApiProjectEntryAction>
    >,
): ApiProjectEntry {
    const { included, meta, relationships } = response;

    const includes = hydrateRelationships( relationships, included );

    // The first action, if available, is the action
    // that triggered the creation or the last update
    // of the given project entry.
    const actions = includes.actions as ApiProjectEntryAction[] | undefined;
    const triggerAction = actions ? actions[0] : undefined;

    return {
        ...response.attributes,
        rfxState: meta?.rfxState,
        triggerAction: triggerAction && triggerAction,
        uuid: response.id,
        ...includes,
        visited: meta?.visited ?? false,
    };
};

// endregion
