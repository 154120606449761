/**
 * ProjectEntry wraps ApiProjectEntry.
 */
import type { ApiProjectEntry } from '@main/api/resources/projects';
import { countryNameFromAlpha3 } from '@main/utilities/geo';
import { normalizeUrl } from '@main/utilities/urls';

export type ProjectEntryState = 'selected' | 'excluded' | 'none';

export class ProjectEntry {
    constructor( private readonly resource: ApiProjectEntry ) {}

    apiResource() {
        return this.resource;
    }

    state() {
        // Once an entry is excluded, we do not consider it selected anymore,
        // even though it may still be assigned to a phase.
        if ( this.resource.excluded ) {
            return 'excluded';
        }

        // An entry is considered selected when it is assigned to a phase.
        if ( this.resource.phaseUuid ) {
            return 'selected';
        }

        return 'none';
    }

    isVisited() {
        return this.resource.visited;
    }

    isConsideredVisited() {
        return this.isVisited() || this.state() === 'selected';
    }

    isExcluded() {
        return this.resource.excluded;
    }

    isAssignedToPhase() {
        return this.phaseUuid() !== null;
    }

    isReference() {
        return this.resource.reference;
    }

    keywords() {
        return this.resource.keywords || [];
    }

    phaseUuid() {
        if ( this.state() === 'excluded' ) {
            return null;
        }

        return this.resource.phaseUuid;
    }

    company() {
        return this.resource.company;
    }

    legalName() {
        return this.company().legalName;
    }

    name() {
        return this.company().name;
    }

    uuid() {
        return this.company().uuid;
    }

    countryAndCity() {
        if ( this.countryName() && this.locality() ) {
            return this.countryName() + ', ' + this.locality();
        }

        if ( this.company().address?.country ) {
            return this.countryName();
        }

        return '';
    }

    countryName() {
        const code = this.company().address?.country ?? '';

        if ( code === '' ) {
            return '';
        }

        return countryNameFromAlpha3( code );
    }

    locality() {
        // Corresponds to the city in most cases.
        return this.company().address?.locality || '';
    }

    location() {
        return this.company().location || null;
    }

    logoUrl() {
        return this.company().logoUrl || '';
    }

    hasUrl() {
        return !!this.url();
    }

    url() {
        const url = this.company().url;

        if ( !url ) {
            return '';
        }

        return normalizeUrl( url );
    }

    rfxState() {
        return this.resource.rfxState;
    }

    discoveryState() {
        return this.resource.discoveryState;
    }

    exclusionReason() {
        if ( !this.resource.excluded ) {
            return undefined;
        }

        if ( this.resource.reported ) {
            return 'Unsuitable';
        }

        return 'Internal reasons';
    }
}
