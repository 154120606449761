import { authenticate, startSignIn } from '@main/api/auth';
import ApiMaximumRetriesExceededError from '@main/api/errors/ApiMaximumRetriesExceededError';
import { METHOD_GET, METHOD_PATCH, METHOD_POST, METHOD_PUT } from '@main/api/plugin/types/api';
import { matchVersion } from '@main/domain/version';
import { useFeatureStore } from '@main/store/stores/feature';
import type { AfterResponseHook, BeforeRequestHook, BeforeRetryHook } from 'ky';
import ky, { HTTPError } from 'ky';

/**
 * Ky client factory
 *
 * As we use ky to make requests, we use a single, pre-configured instance with
 * several settings pre-configured.
 */
export function clientFactory( cache: Map<string, Response> ) {
    let beforeRequest = [checkCacheHook( cache ), authenticate];
    let afterResponse = [
        handleMaintenanceModeHook(),
        handleAuthProblemsHook(),
        checkVersionHook(),
        syncFeaturesHook(),
        politeRetryHook(),
        updateCacheHook( cache ),
    ];
    const beforeRetry = [handlePoliteRetryAttempts()];

    // If we are in development mode, we want to log the requests and responses.
    // In production, it will be tree-shaken out by the bundler.
    if ( import.meta.env.DEV ) {
        beforeRequest = [openLogGroupHook(), ...beforeRequest];
        afterResponse = [...afterResponse, closeLogGroupHook()];
    }

    return ky.extend( {
        // TODO revert back to 20 seconds
        // once search query performance has been improved.
        timeout: 30_000,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        retry: {
            maxRetryAfter: 60_000,
            limit: 5,
        },
        hooks: {
            beforeRequest,
            afterResponse,
            beforeRetry,
        },
    } );
}

function openLogGroupHook(): BeforeRequestHook {
    return function openLogGroup( request ) {
        console.debug( `[http] ${request.method} ${request.url}: Dispatched request`, request );
    };
}

function closeLogGroupHook(): AfterResponseHook {
    return function closeLogGroup( request, _options, response ) {
        console.debug( `[http] ${request.method} ${request.url}: Received response`, response );
    };
}

/**
 * Checks whether a response has been cached previously and returns the cached
 * instance, if any.
 *
 * @param cache
 */
function checkCacheHook( cache: Map<string, Response> ): BeforeRequestHook {
    return function checkCache( request ) {
        // If this is a GET request, and we have the response in the
        // cache, return the cached response.
        if (
            request.method === METHOD_GET &&
            cache.has( request.url ) &&
            request.cache !== 'no-store'
        ) {
            // As opposed to the other logs, let's always show this one
            // without requiring verbose console mode, for the following reasons:
            //
            // 1) I have lost quite a bit of time, not knowing that
            //    responses came from the cache.
            // 2) If verbose is active, it is pretty hard to
            //    spot this log entry.

            if ( import.meta.env.DEV ) {
                console.log( `[http.cache] ${request.method} ${request.url}: Cache Hit` );
            }

            return cache.get( request.url );
        }

        if ( import.meta.env.DEV ) {
            console.debug( `[http.cache] ${request.method} ${request.url}: Cache Miss` );
        }
    };
}

/**
 * Stores a response in the cache for future usage. Only those responses created
 * from GET requests, with a successful status code, and no cache defeating
 * header directives will be stored.
 *
 * @param cache
 */
function updateCacheHook( cache: Map<string, Response> ): AfterResponseHook {
    return function updateCache( request, _options, response ) {
        // If this was a GET request, and we have a successful response, store
        // it in the cache.
        if (
            request.method === METHOD_GET &&
            response.status === 200 &&
            request.cache !== 'no-store'
        ) {
            if ( import.meta.env.DEV ) {
                console.debug( `[http.cache] ${request.method} ${request.url}: Caching response` );
            }

            cache.set( request.url, response );
        } else if ( import.meta.env.DEV ) {
            console.debug(
                `[http.cache] ${request.method} ${request.url}: ` +
                    'Skipping cache: Uncachable request',
            );
        }

        // Invalidate the cache for writing requests to a resource. This makes
        // sure the cache for a resource is purged if it is updated.
        if ( [METHOD_PUT, METHOD_POST, METHOD_PATCH].includes( request.method ) ) {
            if ( import.meta.env.DEV ) {
                console.debug(
                    `[http.cache] ${request.method} ${request.url}: Flushed cached response`,
                );
            }

            cache.delete( request.url );
        } else if ( import.meta.env.DEV ) {
            console.debug(
                `[http.cache] ${request.method} ${request.url}: Skipping flush: No write request`,
            );
        }

        return response;
    };
}

/**
 * Make sure the current client version corresponds to the server version.
 *
 * @param headerName Name of the header containing the version
 * @param excludePaths Paths that should be excluded from the version check
 */
function checkVersionHook(
    headerName = 'Matchory-Release',
    excludePaths: string[] = ['oauth/signout'],
): AfterResponseHook {
    return async function checkVersion( request, _options, response ) {
        // Some urls should pass, even if the version is not matching.
        if ( urlContainsPath( request.url, excludePaths ) ) {
            return;
        }

        const release = response.headers.get( headerName );

        if ( !release ) {
            throw new Error( `The "${headerName}" header is missing from the response` );
        }

        await matchVersion( release );
    };
}

/**
 * Make sure the currently available features are up-to-date.
 *
 * @param headerName Name of the header containing the features
 */
function syncFeaturesHook( headerName = 'Matchory-Feature' ): AfterResponseHook {
    return async function syncFeatures( _request, _options, response ) {
        const store = useFeatureStore();
        const features = response.headers.get( headerName )?.split( ', ' );

        if ( !features ) {
            return;
        }

        let parsedFeatures;

        try {
            parsedFeatures = Object.fromEntries(
                features.map( ( feature ) => {
                    const [name, value] = feature.split( '=', 2 );

                    return [name, value ? JSON.parse( value ) : true];
                } ),
            );
        } catch {
            throw new Error( `The "${headerName}" header contains invalid feature data` );
        }

        store.setFeatures( parsedFeatures );
    };
}

function urlContainsPath( url: string | URL, paths: string[] ) {
    return paths.some( ( path ) => url.toString().includes( path ) );
}

function handleAuthProblemsHook(): AfterResponseHook {
    return function handleAuthProblems( _request, _options, response ) {
        if ( response.status === 401 ) {
            return startSignIn();
        }

        return response;
    };
}

function handleMaintenanceModeHook(): AfterResponseHook {
    return async function handleMaintenanceMode( _request, _options, response ) {
        if ( response.status === 503 ) {
            window.location.href = import.meta.env.VITE_MAINTENANCE_MODE_URL;

            return;
        }

        return response;
    };
}

/**
 * Handle server-initiated retry requests.
 *
 * If the server responds with a success status code that indicates a retry is
 * requested, such as 202 - Accepted, Ky will not throw an error, and thus never
 * attempt to retry the request. This hook will throw an error in such cases to
 * trigger a retry manually.
 * The error thrown has a special marker property that we can check for in the
 * sibling hook to determine whether the maximum retry amount has been exceeded,
 * so it can in turn throw an ordinary HTTPError to let the client code know the
 * request has failed permanently.
 */
function politeRetryHook(): AfterResponseHook {
    return function ( request, options, response ) {
        if ( response.ok && options.retry.statusCodes?.includes( response.status ) ) {
            const error = new HTTPError( response, request, options );
            ( error as RetryError )._retryRequested = true;

            throw error;
        }
    };
}

/**
 * Handle polite retry attempts.
 *
 * If the response handling hook determined the request should be retried, we
 * will work off of the error object to determine whether the maximum amount of
 * retries has been reached. If so, we will throw the error, such that it can
 * finally bubble up to the caller.
 */
function handlePoliteRetryAttempts(): BeforeRetryHook {
    return function ( { error, retryCount, options } ) {
        if ( !isRetryRequestedError( error ) ) {
            return;
        }

        if ( retryCount === options.retry.limit ) {
            throw new ApiMaximumRetriesExceededError( error.request, error.response, retryCount );
        }
    };
}

type RetryError = HTTPError & { _retryRequested: boolean; };

function isRetryRequestedError( error: Error ): error is RetryError {
    return '_retryRequested' in error && error._retryRequested === true;
}
