import ApiVersionError from '@main/api/errors/ApiVersionError';
import { flushAll as flushAllPersistentStores } from '@main/store/persistentStorage';
import { useAuthStore } from '@main/store/stores/auth';
import { useNotificationsStore } from '@main/store/stores/notifications';

export const clientVersion = import.meta.env.VITE_APP_VERSION?.substring( 0, 7 );

export async function matchVersion( serverVersion: string ) {
    if ( isClientUpToDate( serverVersion ) ) {
        return;
    }

    const error = new ApiVersionError( serverVersion, clientVersion );

    await reloadApp( error );
}

function isClientUpToDate( currentVersion: string ) {
    return currentVersion.substring( 0, 7 ) === clientVersion;
}

let isReloading = false;

export async function reloadApp( error: ApiVersionError ) {
    if ( isReloading ) {
        return;
    }

    isReloading = true;

    notify( error );
    reload( error );

    // Reload should happen soon. Meanwhile, interrupt the chain of execution by throwing
    // a meaningful exception.
    throw error;
}

function notify( error: ApiVersionError, askUserToReload = false ) {
    const detail = askUserToReload
        ? [
              'Reloading application…',
              'Please refresh the page if your application does not reload automatically.',
          ]
        : ['Reloading application…', 'Please wait for the latest version.'];

    if ( useAuthStore().hasCapability( 'debug' ) ) {
        detail.push( error.message );
    }

    useNotificationsStore().notify( {
        message: 'Update available',
        detail,
        type: 'info',
        persistent: true,
    } );

    return 'Reloading application…';
}

function reload( error: ApiVersionError ) {
    setTimeout( async () => {
        // Flush all stores so newer store versions can be initialized properly.
        await flushAllPersistentStores();

        // Only now that we have flushed the stores, tell the user to reload the page.
        notify( error, true );

        // This will require the user to sign in again as we flushed the stores, and the redirect to
        // the current location.
        window.location.reload();
    }, 5000 );
}
