import { fatalErrorHandler } from '@/.matchory/utilities';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import App from '@main/App.vue';
import { contextPlugin } from '@main/plugins/context';
import { draggablePlugin } from '@main/plugins/draggable';
import vuetify from '@main/plugins/vuetify';
import router from '@main/router';
import pinia from '@main/store';
import '@main/styles/tailwind.postcss';
import { createHead } from '@unhead/vue';
import { UnheadPlugin } from '@unhead/vue/vue2';
import Vue from 'vue';

Vue.config.productionTip = true;
Vue.config.devtools = ['development', 'staging'].includes( import.meta.env.MODE );

Vue.use( contextPlugin );
Vue.use( draggablePlugin );

const unhead = createHead();
Vue.use( UnheadPlugin );

async function main() {
    if ( import.meta.env.PROD || import.meta.env.VITE_ACTIVATE_THIRD_PARTY_PLUGINS === 'true' ) {
        const [{ sentryPlugin }, { mixpanelPlugin }] = await Promise.all( [
            import( '@main/plugins/sentry' ),
            import( '@main/plugins/mixpanel' ),
        ] );

        Vue.use( sentryPlugin, {
            baseUrl: import.meta.env.VITE_BASE_URL,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            traceSampleRate: import.meta.env.PROD ? 0.2 : 1.0,
        } );

        Vue.use( mixpanelPlugin, {
            token: import.meta.env.VITE_MIXPANEL_TOKEN,
            config: {
                // EU data residency is ensured through backend proxy
                api_host: `${import.meta.env.VITE_BASE_URL}/.tracking`,
                debug: import.meta.env.DEV,
                ignore_dnt: import.meta.env.DEV,
            },
        } );
    } else if ( import.meta.env.DEV ) {
        console.log( 'Third party plugins are disabled in development mode' );
    }

    /**
     * Apparently, this order makes sure pinia is present in Vue devtools.
     *
     * @see https://github.com/vuejs/devtools/issues/1839#issuecomment-1592194089
     */
    const app = new Vue( {
        pinia,
        render: ( h ) => h( App ),
        router,
        unhead,
        vuetify,
    } );
    app.$mount( '#app' );
}

// By starting the application explicitly, we can use dynamic imports, speeding
// up the code in development (as we usually don't load third-party reporting
// tools, so we don't even need to load these modules on initialization).
// Additionally, the initialization error handler allows us to render kind of a
// "last line of defense" error message to users, should all else fail, instead
// of just having a blank screen.
main().catch( ( error ) => fatalErrorHandler( error ) );
