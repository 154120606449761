import { context } from '@main/api';
import type { ResourcePayload } from '@main/utilities/types';

export type ApiCustomer = {
    readonly uuid: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly active: boolean;
    readonly ownerName: string;

    // mutable
    name: string;
    website: string;
    phoneNumber: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    countryCode: string | null;
    country: string;
    vatId: string;
    invoiceEmail: string;
};

export type UpdatedApiCustomer = ResourcePayload<ApiCustomer>;

export type ApiCustomerContext = {
    readonly uuid: string;
    readonly name: string;
    readonly type: string; // TODO: Extend with other contexts
    readonly logoUrl: string;
};

const { single, update } = context;

export async function fetchCustomer(): Promise<ApiCustomer> {
    const { attributes, meta } = await single<ApiCustomer>(
        import.meta.route( 'api.v0.customers.self' ),
    );

    return {
        ...attributes,
        ...meta,
    };
}

export async function updateCustomer( customer: UpdatedApiCustomer ) {
    await update<ApiCustomer>( import.meta.route( 'api.v0.customers.self.update' ), customer );
}
