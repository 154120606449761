// TODO: RFI import { flushAll } from '@main/store/persistentStorage';
import { useAuthStore } from '@main/store/stores/auth';
import { useTrackingStore } from '@main/store/stores/tracking';
import Vue from 'vue';
import type { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import VueRouter from 'vue-router';

Vue.use( VueRouter );

// Auth
export const ROUTE_AUTH_AUTHORIZE = 'auth.authorize';
export const ROUTE_AUTH_SIGNIN = 'auth.signin';

// Top level
export const ROUTE_HOME = 'home';
// export const ROUTE_ONBOARDING_ROOT = 'onboarding.root';
export const ROUTE_WELCOME = 'welcome';

// Projects
export const ROUTE_PROJECTS = 'projects';
export const ROUTE_PROJECT = 'project';
export const ROUTE_PROJECT_DASHBOARD = 'project.dashboard';
export const ROUTE_PROJECT_SEARCH = 'project.search';
export const ROUTE_PROJECT_POOL = 'project.pool';
export const ROUTE_PROJECT_RFI = 'project.rfi';
export const ROUTE_PROJECT_CHECKLIST = 'project.requirements';
export const ROUTE_PROJECT_SELECTION = 'project.selection';

export const ROUTE_PROJECT_WIZARD = 'project.wizard';

// Redirections
export const ROUTE_REDIRECTION_TO_CONVERSATION = 'redirection.conversation';
export const ROUTE_REDIRECTION_TO_CONTACTS = 'redirection.contacts';
export const ROUTE_REDIRECTION_TO_RFX = 'redirection.rfx';
export const ROUTE_REDIRECTION_TO_PROJECT_ENTRY = 'redirection.project.entry';

// Supplier
export const ROUTE_COMPANY_PROFILE = 'company.profile';

//  Settings
export const ROUTE_SETTINGS = 'settings';
export const ROUTE_SETTINGS_CUSTOMER = 'settings.customer';
export const ROUTE_SETTINGS_USER = 'settings.user';
export const ROUTE_SETTINGS_TEAM = 'settings.team';

// Invitation
export const ROUTE_INVITATION_RFX_AUTHORIZE = 'invitation.rfx.authorize';
export const ROUTE_INVITATION_RFX_AUTHORIZED = 'invitation.rfx.authorized';
export const ROUTE_INVITATION_RFX = 'invitation.rfx';
export const ROUTE_INVITATION_RFX_EXPIRED = 'invitation.rfx.expired';

// No route is defined for this one, so the final catch-all matches
// and renders "page not found".
export const PATH_404 = '/404';

/** Router Config */
export const routes: RouteConfig[] = [
    {
        path: '/',
        name: ROUTE_HOME,
        component: () => import( '@main/views/Home.vue' ),
        meta: {
            public: true,
        },
    },

    // region auth

    {
        path: '/auth/signin',
        name: ROUTE_AUTH_SIGNIN,
        component: () => import( '@main/views/Auth/SignIn.vue' ),
        props: true,
        meta: {
            public: true,
            tracking: false,
        },
    },
    {
        path: '/auth/authorize',
        name: ROUTE_AUTH_AUTHORIZE,
        component: () => import( '@main/views/Auth/Authorize.vue' ),
        meta: {
            public: true,
            tracking: false,
        },
    },

    // endregion

    // region projects

    {
        path: '/projects',
        name: ROUTE_PROJECTS,
        component: () => import( '@main/views/Projects/Projects.vue' ),
        props: ( { query } ) => ( {
            searchTerm: query.query,
            page: query.page ? Number( query.page ) : undefined,
            sort: query.sort,
            tags: Array.isArray( query.tags ) || !query.tags ? query.tags : [query.tags],
        } ),
    },
    {
        path: '/projects/:uuid/wizard/:state?',
        name: ROUTE_PROJECT_WIZARD,
        component: () => import( '@main/views/Projects/Wizard.vue' ),
        props: true,
    },
    {
        path: '/projects/:uuid',
        component: () => import( '@main/views/Projects/Project.vue' ),
        props: true,
        children: [
            {
                path: '',
                name: ROUTE_PROJECT,
                redirect( to ) {
                    return {
                        name: ROUTE_PROJECT_SEARCH,
                        params: {
                            uuid: to.params.uuid,
                        },
                    };
                },
            },

            {
                path: 'search/:queryUuid?',
                name: ROUTE_PROJECT_SEARCH,
                component: () => import( '@main/components/App/Projects/Features/ProjectSearch.vue' ),
                props: true,
            },

            // TODO: PROJECTS
            /*
             {
             path: 'dashboard',
             name: ROUTE_PROJECT_DASHBOARD,
             component: () => import('@main/components/App/Projects/ProjectDashboard.vue'),
             props: true,
             },
             {
             path: 'pool/:queryUuid?',
             name: ROUTE_PROJECT_POOL,
             component: () => import('@main/components/App/Projects/ProjectPool.vue'),
             props: true,
             },
             */
            // TODO: RFI
            /*
             {
             path: 'rfi',
             name: ROUTE_PROJECT_RFI,
             component: () => import('@main/components/App/Projects/ProjectRfi.vue'),
             props: true,
             },
             */
            {
                path: 'checklist',
                name: ROUTE_PROJECT_CHECKLIST,
                component: () =>
                    import( '@main/components/App/Projects/Features/ProjectChecklist.vue' ),
                props: true,
            },
            {
                path: 'selection',
                name: ROUTE_PROJECT_SELECTION,
                component: () =>
                    import( '@main/components/App/Projects/Features/ProjectSelection.vue' ),
                props: true,
            },
        ],
    },
    // endregion

    // region redirections

    {
        path: '/conversations/:uuid',
        name: ROUTE_REDIRECTION_TO_CONVERSATION,
        component: () => import( '@main/views/Redirection/ConversationRedirection.vue' ),
        props: true,
    },
    {
        path: '/contacts',
        name: ROUTE_REDIRECTION_TO_CONTACTS,
        component: () => import( '@main/views/Redirection/ContactsRedirection.vue' ),
        props: true,
    },
    // TODO: RFI
    /*
     {
     path: '/rfx',
     name: ROUTE_REDIRECTION_TO_RFX,
     component: () => import('@main/views/Redirection/RfxRedirection.vue'),
     props: true,
     },
     */

    {
        path: '/projects/:uuid/entry/:entryUuid',
        name: ROUTE_REDIRECTION_TO_PROJECT_ENTRY,
        component: () => import( '@main/views/Redirection/ProjectEntryRedirection.vue' ),
        props: true,
    },

    // endregion

    // region company profiles

    {
        path: '/companies/:uuid',
        component: () => import( '@main/views/Companies/CompanyProfile.vue' ),
        props: true,
        meta: {
            scrollOffset: 120 /* to account for profile anchor navigation */,
        },
        children: [
            {
                path: '',
                name: ROUTE_COMPANY_PROFILE,
                component: () => import( '@main/components/App/Company/Profile/ProfileWrapper.vue' ),
                props: true,
            },
        ],
    },

    /*
     {
     // SLUG
     path: '/:countryCode([a-z]{2,3})/:city/:name',
     component: () => import( '@main/views/Profiles/Profile.vue' ),
     props: true,
     meta: {
     scrollOffset: 120 // to account for profile anchor navigation,
     },
     children: [
     {
     path: '',
     name: ROUTE_SUPPLIER_PROFILE,
     component: () => import('@main/components/App/Company/Profile/ProfileWrapper.vue'),
     props: true,
     },
     ],
     },
     */

    // endregion

    // region onboarding

    {
        path: '/welcome',
        name: ROUTE_WELCOME,
        component: () => import( '@main/views/Onboarding/Welcome.vue' ),
    },

    // endregion

    // region settings

    {
        path: '/settings',
        name: ROUTE_SETTINGS,
        component: () => import( '@main/views/Settings/Settings.vue' ),
        children: [
            {
                path: 'customer',
                name: ROUTE_SETTINGS_CUSTOMER,
                component: () => import( '@main/views/Settings/Customer.vue' ),
            },
            {
                path: 'user',
                name: ROUTE_SETTINGS_USER,
                component: () => import( '@main/views/Settings/User.vue' ),
            },
            {
                path: 'team',
                name: ROUTE_SETTINGS_TEAM,
                component: () => import( '@main/views/Settings/Team.vue' ),
            },
        ],
    },

    //endregion

    // region invitation

    // TODO: RFI
    /*
     {
     path: '/invitation/rfx/:project/:stage/:invitation',
     name: ROUTE_INVITATION_RFX,
     async beforeEnter( _to: Route, _from: Route, next: NavigationGuardNext ) {

     const store: AuthStore = useAuthStore();
     const rfxScope: string = import.meta.env.VITE_AUTH_RFX_SCOPES;

     // If there is a valid token in the store, but the user isn't
     // authorized for the RFX scope, they must be a regular user that
     // has clicked on an invitation link.
     // They don't have permission to use the landing page however, so we
     // will simply redirect them to the dashboard.
     if ( store.hasValidToken && !store.hasScope( rfxScope ) ) {
     return next( {
     name: ROUTE_HOME,
     } );
     }

     // Flush the entire local storage so that any auth data of
     // a previously authorized RFX user who either is
     // - the same RFX contact as the currently requesting one,
     //   opening another RFX invitation
     // - another RFX contact as the currently requesting one,
     //   opening another RFX invitation
     // gets wiped out, and we authorize the current RFX contact instead.
     //
     // The first scenario is expected, while covering the second scenario
     // is mainly important for testing.
     //
     // This also removes the auth data when the requesting
     // RFX contact is in deed the one whose auth data is stored.
     // But that's no problem. We just authorize him again.
     await flushAll();

     // We redirect to the authorization page.
     next( {
     name: ROUTE_INVITATION_RFX_AUTHORIZE,
     query: { ..._to.params, ..._to.query },
     } );
     },
     props: ( { params }: Route ) => ( {
     projectUuid: params.project,
     rfxStageUuid: params.stage,
     rfxInvitationUuid: params.invitation,
     } ),
     meta: {
     // Ensure the page is public, so it won't get picked up by the
     // generic auth guard below.
     public: true,
     },
     },
     */

    // TODO: RFI
    /*
     {
     path: '/invitation/rfx/authorize',
     name: ROUTE_INVITATION_RFX_AUTHORIZE,
     component: () => import('@main/views/Auth/AuthorizeRfx.vue'),
     beforeEnter( _to: Route, _from: Route, next: NavigationGuardNext ) {

     const store: AuthStore = useAuthStore();
     const rfxScope: string = import.meta.env.VITE_AUTH_RFX_SCOPES;

     // If there is a valid token in the store, the user must have been
     // authorised previously - either as a regular user, or as an
     // anonymous RFX invitee.
     if ( store.hasValidToken ) {

     // If the user lacks the rfx scope, however, they must be an
     // ordinary user, and will be redirected to the home page.
     if ( !store.hasScope( rfxScope ) ) {
     return next( {
     name: ROUTE_HOME,
     } );
     }

     // Apparently the user has the rfx scope, so they must be an
     // anonymous RFX user that has been authorised before, so we can
     // simply redirect them to the RFX landing page again.
     // This will happen if the user navigates back in their browser,
     // for example.
     return next( {
     name: ROUTE_INVITATION_RFX_AUTHORIZED,
     params: ( { ..._to.params, ..._to.query } as Record<string, string> ),
     } );
     }

     // The user does NOT have a valid token yet, so we proceed to the
     // authorize page.
     next();
     },
     meta: {
     public: true,
     tracking: false,
     },
     },
     */

    // TODO: RFI
    /*
     {
     path: '/invitation/rfx/authorized/:project/:stage/:invitation',
     name: ROUTE_INVITATION_RFX_AUTHORIZED,
     component: () => import('@main/views/Invitations/RfxInvitation.vue'),
     beforeEnter( _to: Route, _from: Route, next: NavigationGuardNext ) {

     const store: AuthStore = useAuthStore();
     const rfxScope: string = import.meta.env.VITE_AUTH_RFX_SCOPES;

     // If there is a valid token in the store, but the user isn't
     // authorized for the RFX scope, they must be a regular user that
     // has clicked on an invitation link.
     // They don't have permission to use the landing page however, so we
     // will simply redirect them to the dashboard.
     if ( store.hasValidToken && !store.hasScope( rfxScope ) ) {
     return next( {
     name: ROUTE_HOME,
     } );
     }

     if ( !store.hasScope( rfxScope ) ) {
     // This happens, when the link to this route was copied
     // and used in another browser instance.
     // We just redirect to authorization.
     return next( {
     name: ROUTE_INVITATION_RFX_AUTHORIZE,
     query: { ..._to.params, ..._to.query },
     } );
     }

     // The user does have a valid token and is authorized for the RFX
     // scope, so we can proceed to the RFX invitation page.
     next();
     },
     props: ( { params }: Route ) => ( {
     projectUuid: params.project,
     rfxStageUuid: params.stage,
     rfxInvitationUuid: params.invitation,
     } ),
     meta: {
     // Ensure the page is public, so it won't get picked up by the
     // generic auth guard below
     public: true,
     },
     },
     */

    // TODO: RFI
    /*
     {
     path: '/invitation/rfx-expired',
     name: ROUTE_INVITATION_RFX_EXPIRED,
     component: () => import('@main/views/Invitations/RfxInvitationExpired.vue'),
     meta: {
     public: true,
     },
     },
     */

    //endregion

    {
        path: '*',
        name: 'error',
        component: () => import( '@main/views/PageNotFound.vue' ),
        meta: {
            public: true,
            tracking: false,
        },
    },
];

const router: VueRouter = new VueRouter( {
    base: import.meta.env.BASE_URL,
    mode: 'history',

    /**
     * We ignore typescript check here because we want to return false when
     * data is updated on the same path. Typescript only allows returning
     * undefined here, but then a TypeError assertion is thrown.
     * This behavior probably will be fixed in a higher version of vue router.
     */
    scrollBehavior( to, from, savedPosition ) {

        /**
         * Prevent scrolling via router, when route props other than the
         * path change in the search route.
         *
         * @see https://v3.router.vuejs.org/guide/advanced/scroll-behavior.html
         */
        if ( to.name === ROUTE_PROJECT_SEARCH && to.path === from.path ) {
            return undefined;
        }

        if ( to.hash ) {
            return { selector: to.hash };
        }

        if ( savedPosition ) {
            return savedPosition;
        }

        return undefined;
    },
    routes,
} );

router.beforeEach( async ( _to: Route, _from: Route, next: NavigationGuardNext ) => {
    await Vue.nextTick();

    const store = useAuthStore();

    if ( !_to.meta?.public && !store.hasValidToken ) {
        return next( {
            name: ROUTE_AUTH_SIGNIN,
            params: {
                redirectUrl: _to.fullPath,
            },
        } );
    }

    if ( _to.name === ROUTE_HOME && store.hasValidToken ) {
        return next( { name: import.meta.env.VITE_ROUTE_HOME } );
    }

    return next();
} );

router.afterEach( async ( to, from ) => {
    // Hide Loading
    // store.dispatch('setLoading', false);

    // Ignore some routes
    if ( to.name === null || to.meta?.tracking === false ) {
        console.debug( `Tracking disabled for route: ${to.name}` );

        return;
    }

    if ( from.name === ROUTE_AUTH_AUTHORIZE ) {
        useAuthStore().welcomeBackModalActive = true;
    }

    void useTrackingStore().sendPageViewed( from, to );
} );

export default router;
