// noinspection JSUnusedLocalSymbols
import type { ListOptions } from '@main/api/plugin/types/api';
import { createMediaItem } from '@main/api/resources/media';
import {
    addProjectCollaborator,
    type AggregatableField,
    type ApiCollaborator,
    type ApiProject,
    type ApiProjectIncludes,
    type ApiProjectPhase,
    type ApiProjectWithPhases,
    createProject,
    deleteProject,
    fetchProject,
    fetchProjectCollaborator,
    fetchProjectCollaborators,
    fetchProjectPhases,
    fetchProjects,
    type NewApiProject,
    type ProjectPermissionsGrants,
    removeProjectCollaborator,
    type UpdatedApiProject,
    updateProject,
    updateProjectCollaborator,
} from '@main/api/resources/projects';
import { Project } from '@main/domain/projects/Project';
import { useAssistantsStore } from '@main/store/stores/assistants';
import { useProjectEntriesStore } from '@main/store/stores/project-entries';
import { useTrackingStore } from '@main/store/stores/tracking';
import type { ResizeOptions } from '@main/utilities/files';
import { patchList } from '@main/utilities/store';
import { acceptHMRUpdate, defineStore } from 'pinia';
import Vue from 'vue';

type State = {
    aggregations: Partial<Record<AggregatableField, Record<string, number>>>;
    apiCollaborators: Record<string, ApiCollaborator[]>;
    apiProjects: ApiProjectWithPhases[];
    createProjectActive: boolean;
    totalProjects: number;
};

export const useProjectsStore = defineStore( 'projects', {
    persist: false,

    state(): State {
        return {
            // These are the projects a user can paginate through in the projects list.
            apiProjects: [],
            totalProjects: 0,

            // All collections are stored per project UUID
            apiCollaborators: {},

            aggregations: {},

            createProjectActive: false,
        };
    },

    actions: {
        // region Projects

        async createProject( project: NewApiProject, image?: File ) {
            let mediaItemUuid: string | undefined = undefined;

            if ( image ) {
                const options: ResizeOptions = {
                    longestEdge: 1200,
                    jpegQuality: 0.72,
                };

                mediaItemUuid = await createMediaItem( image, 'projectImage', options );
            }

            const apiProject = await createProject( {
                imageUuid: mediaItemUuid,
                ...project,
            } );

            const trackingStore = useTrackingStore();
            trackingStore.sendProjectCreated( apiProject );

            // Fetch the phases and collaborators as we want them to always be available.
            const results: [ApiProjectPhase[], ApiCollaborator[]] = await Promise.all( [
                fetchProjectPhases( apiProject.uuid ),
                fetchProjectCollaborators( apiProject.uuid ),
            ] );

            const phases = results[0];
            const collaborators = results[1];

            // As opposed to phases, the collaborators are handled as
            // separate entities in the store, so we hydrate the phases ...
            const newProject: ApiProjectWithPhases = {
                ...apiProject,
                phases,
            };

            // ... and store the collaborators.
            Vue.set( this.apiCollaborators, apiProject.uuid, collaborators );

            // Now store the new project.
            this.apiProjects.unshift( newProject );

            return newProject;
        },

        async updateProject(
            projectUuid: string,
            data: UpdatedApiProject,
            image?: File,
        ): Promise<void> {
            let mediaItemUuid: string | undefined = undefined;

            if ( image ) {
                const options: ResizeOptions = {
                    longestEdge: 1200,
                    jpegQuality: 0.72,
                };

                mediaItemUuid = await createMediaItem( image, 'projectImage', options );
            }

            const updatedProject = await updateProject( projectUuid, {
                imageUuid: mediaItemUuid,
                ...data,
            } );

            const currentProject = this.apiProjects.find( ( { uuid } ) => uuid === projectUuid );

            const project = {
                ...updatedProject,
                // We shall have all phases.
                phases: currentProject?.phases || [],
                // Collaborators and entries are not necessarily available.
                collaborators: currentProject?.collaborators,
                entries: currentProject?.entries,
            };

            this.$patch( ( state ) => {
                state.apiProjects = patchList( state.apiProjects, project, 'uuid' );
            } );

            // If a finalized project is updated by adding references, re-fetch
            // them to reflect the change in the UI.
            if ( data.referenceUuids && updatedProject.wizardState === 'finalized' ) {
                await useProjectEntriesStore().fetchProjectReferenceEntries( projectUuid );
            }

            // We want to primarily track project updates related to the project wizard.
            if ( data.wizardState ) {
                const step = project.aiEnabled
                    ? useAssistantsStore().getSetupAssistantConversationStep( projectUuid )
                    : undefined;

                const trackingStore = useTrackingStore();
                trackingStore.sendProjectUpdated( project, step );
            }
        },

        async switchToBasicProject( projectToDelete: ApiProject, keywords: string[] ) {
            const assistantsStore = useAssistantsStore();
            useAssistantsStore().stopPolling();

            // Save uuid for tracking.
            const deletedProjectUuid = projectToDelete.uuid;

            await this.deleteProject( projectToDelete );

            const project = await this.createProject( {
                keywords,
                aiEnabled: false,
            } );

            const assistantStep =
                assistantsStore.getSetupAssistantConversationStep( deletedProjectUuid );

            const trackingStore = useTrackingStore();
            trackingStore.sendProjectSwitched( projectToDelete.uuid, project.uuid, assistantStep );

            return project;
        },

        async updateWizardState(
            projectUuid: string,
            wizardState: UpdatedApiProject['wizardState'],
        ) {
            await this.updateProject( projectUuid, { wizardState } );
        },

        async deleteProject( project: ApiProject ) {
            await deleteProject( project.uuid );

            // Send project deleted event
            const trackingStore = useTrackingStore();
            const step = project.aiEnabled
                ? useAssistantsStore().getSetupAssistantConversationStep( project.uuid )
                : undefined;
            trackingStore.sendProjectDeleted( project, step );

            this.removeDeletedProjectFromStore( project.uuid );
        },

        removeDeletedProjectFromStore( projectUuid: string ) {
            const updatedProjects = this.apiProjects.filter(
                ( project ) => project.uuid !== projectUuid,
            );

            this.$patch( ( state ) => {
                state.apiProjects = updatedProjects;
            } );

            this.totalProjects--;
        },

        async fetchProjects(
            options?: ListOptions<ApiProject> & { aggregations?: AggregatableField[]; },
        ) {
            if ( options?.filter?.tags ) {
                const tags = options.filter.tags;

                /**
                 * The user should be able to assign categories
                 * containing a comma, but still filter for
                 * multiple values per filter field. Since they
                 * are separated by comma, we encode the values
                 * and prefix them so our backend can distinguish.
                 */
                options.filter.tags = ( Array.isArray( tags ) ? tags : [tags] ).map(
                    ( t ) => 'base64:' + btoa( t ),
                );
            }

            const { projects, total, aggregations } = await fetchProjects( {
                aggregations: ['tags'], // Default value
                ...options,
                include: ['phases', 'collaborators'],
            } );

            this.totalProjects = total;

            // TODO Project filters: Generalize aggregations, since there will be others, too
            this.aggregations = aggregations;

            // We want to store the included collaborators separately,
            // so we split them from the resource.
            this.apiProjects = projects.map( ( project ) => {
                const { uuid, collaborators } = project;
                let withoutCollaborators = project as ApiProjectWithPhases;

                if ( collaborators ) {
                    Vue.set( this.apiCollaborators, uuid, collaborators );

                    withoutCollaborators = {
                        ...( project as ApiProjectWithPhases ),
                        collaborators: undefined,
                    };
                }

                return withoutCollaborators;
            } );
        },

        async fetchProjectSuggestions( companyUuid: string, term?: string ) {
            const params: ListOptions<ApiProject, ApiProjectIncludes> = { page: 1 };

            if ( companyUuid ) {
                Object.assign( params, { companyUuid } );
            }

            if ( term ) {
                Object.assign( params, { filter: { name: term } } );

                return await fetchProjects( params );
            }

            Object.assign( params, { per_page: 5 }, { sort: ['-updatedAt'] } );

            return await fetchProjects( params );
        },

        async fetchProject(
            projectUuid: string,
            includes: ApiProjectIncludes[] = ['collaborators', 'phases'],
        ) {
            const project = await fetchProject( projectUuid, includes );

            this.apiProjects = patchList( this.apiProjects, project as ApiProjectWithPhases, 'uuid' );

            if ( project.collaborators ) {
                Vue.set( this.apiCollaborators, projectUuid, project.collaborators );
            }
        },

        // endregion

        // region Project Collaborators

        async addProjectCollaborator(
            projectUuid: string,
            userUuid: string,
            permissions: ProjectPermissionsGrants,
        ) {
            await addProjectCollaborator( projectUuid, userUuid, permissions );

            return this.fetchProjectCollaborators( projectUuid );
        },

        async updateProjectCollaborator(
            projectUuid: string,
            userUuid: string,
            permissions: ProjectPermissionsGrants,
        ) {
            await updateProjectCollaborator( projectUuid, userUuid, permissions );

            return this.fetchProjectCollaborators( projectUuid );
        },

        async removeProjectCollaborator( projectUuid: string, userUuid: string ) {
            await removeProjectCollaborator( projectUuid, userUuid );

            return this.fetchProjectCollaborators( projectUuid );
        },

        async fetchProjectCollaborators( projectUuid: string ) {
            const collaborators: ApiCollaborator[] = await fetchProjectCollaborators( projectUuid );

            Vue.set( this.apiCollaborators, projectUuid, collaborators );
        },

        async fetchProjectCollaborator( projectUuid: string, userUuid: string ) {
            const collaborator: ApiCollaborator = await fetchProjectCollaborator(
                projectUuid,
                userUuid,
            );

            this.apiCollaborators[projectUuid] = patchList(
                this.apiCollaborators[projectUuid],
                collaborator,
                'uuid',
            );
        },

        // endregion

        // region Create Project

        setCreateProjectActive( active: boolean ) {
            this.createProjectActive = active;
        },

        // endregion
    },

    getters: {
        // region Projects

        projects( { apiProjects, apiCollaborators } ) {
            return apiProjects.map(
                ( project ) => new Project( project, apiCollaborators[project.uuid] ?? [] ),
            );
        },

        getProject( { apiCollaborators, apiProjects } ) {
            return ( projectUuid: string ) => {
                const project = apiProjects.find( ( { uuid } ) => uuid === projectUuid );

                if ( !project ) {
                    return null;
                }

                const collaborators = apiCollaborators[projectUuid] || [];

                return new Project( project, collaborators );
            };
        },

        otherProjects( { apiCollaborators, apiProjects } ) {
            return ( projectUuid: string ) => {
                return apiProjects
                    .filter( ( { uuid } ) => uuid !== projectUuid )
                    .map( ( project ) => new Project( project, apiCollaborators[project.uuid] || [] ) );
            };
        },

        getProjectCollaborators( { apiCollaborators } ) {
            return ( projectUuid: string ) => apiCollaborators[projectUuid] || [];
        },

        getDesiredAggregations( { aggregations } ) {
            return ( field: AggregatableField ) => aggregations[field] ?? null;
        },

        // endregion
    },
} );

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useProjectsStore, import.meta.hot ) );
}
